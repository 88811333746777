import { Avatar, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { AvatarMenuComponent } from './Menu';
import { useAuth0 } from '@auth0/auth0-react';

export const AvatarComponent: React.FC = () => {
  const { user } = useAuth0();

  const [anchorElUser, setAnchorElUser] = React.useState<HTMLElement | null>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <Tooltip title="Account settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar alt={user?.name} src={user?.picture} />
        </IconButton>
      </Tooltip>
      <AvatarMenuComponent
        anchorEl={anchorElUser}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      />
    </>
  );
};
