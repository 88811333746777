import pattern from 'patternomaly';
import { featureCollection } from '@turf/turf';
import { colorUtils } from '../../Utils/color.utils';
import { IResult, IResultLayer } from '../interfaces/result.interface';
import mapboxgl from 'mapbox-gl';
import { ChartPattern } from '../enums/chart-pattern.enum';

export type ResultSource = { id: string; data: mapboxgl.AnySourceData };

export const buildLayerConfig = (resultId: string, layerIndex: number): IResultLayer => ({
  resultId: `${resultId}-Layer-${new Date().getTime() + Math.random()}`,
  displayName: `Layer ${layerIndex}`,
  data: undefined,
  imageryUri: '',
  tab: {
    visible: false,
    displayName: `Tab ${layerIndex}`,
    pieChart: [],
  },
  details: {
    visible: true,
    columnName: '',
    infoColumns: [
      { displayName: 'Area (m²)', visible: false },
      { displayName: 'Percent (%)', visible: false },
    ],
    classes: [],
  },
  inspector: {
    columnsToDisplay: [],
    displayNamesDictionary: {},
  },
});

export const buildChartConfig = (categories) =>
  categories
    ?.sort((a, b) => (a < b ? -1 : 1))
    .map((category) => ({
      className: category,
      percent: 100 / categories.length,
      fillColor: colorUtils.generateRandomHexColor(),
      fillTransparency: 0,
      fillPattern: undefined,
    }));

export const buildDetailsConfig = (column, chartData) => {
  return {
    visible: true,
    columnName: column,
    defaultEnabled: true,
    infoColumns: [
      { displayName: 'Area(m²)', visible: true },
      { displayName: 'Percent(%)', visible: true },
    ],
    classes: chartData.map((c) => ({
      category: c.className,
      displayName: c.className,
      fillColor: c.fillColor,
      fillTransparency: c.fillTransparency,
      fillPattern: c.fillPattern,
      defaultEnabled: true,
      edgeColor: c.fillColor,
      edgeTransparency: c.fillTransparency,
      edgeThickness: 1,
      infoValues: [c.percent, c.percent],
    })),
  };
};

const buildSourcesV1 = (layer: IResultLayer, data: GeoJSON.FeatureCollection): ResultSource[] => {
  const property = layer.details.columnName;
  const sources = [];

  if (!!property) {
    const featuresByCategory = data.features.reduce((group, feature) => {
      const category = feature.properties[property]?.toString().replace(/\s/g, '-');
      group[category] = group[category] || [];
      group[category].push(feature);
      return group;
    }, {});

    const features = Object.keys(featuresByCategory)?.map((category) => {
      const collection = featureCollection(featuresByCategory[category]);
      return {
        id: `${layer.resultId}_${property}_${category}`,
        data: collection,
      };
    });

    features
      ?.map((feature) => ({
        id: feature.id,
        data: {
          type: 'geojson',
          data: feature.data as any,
        },
      }))
      .forEach((source) => sources.push(source));
  }

  if (layer.imageryUri) {
    sources.push({
      id: `${layer.resultId}_${property}_imagery`,
      data: {
        type: 'raster',
        url: layer.imageryUri,
      } as any,
    });
  }

  return sources as any;
};

export const buildSources = (
  layer: IResultLayer,
  shapes?: { data: GeoJSON.FeatureCollection; fileName: string; key: string }[],
): ResultSource[] => {
  if (!shapes?.length && !!layer.data) {
    return buildSourcesV1(layer, layer.data); // Compatibility with old data
  }

  if (!shapes?.length || !layer.shapeKeys?.length) return [];

  const data = featureCollection(
    shapes
      ?.filter((shape) => layer.shapeKeys.includes(shape.key))
      ?.map((shape) => shape.data.features)
      ?.flat(),
  );

  return buildSourcesV1(layer, data);
};

export const buildLayersPatterns = (sources: ResultSource[], result: IResult): Record<string, string> => {
  const map: Record<string, string> = {};

  sources?.forEach((source) => {
    const [resultId, , value] = source.id.split('_');
    const layerResult = result.layers.find((layer) => layer.resultId === resultId);
    const detailClass = layerResult?.details?.classes?.find(
      (c) => c.category.toString().replace(/\s/g, '-') === value,
    );

    if (
      !detailClass?.fillPattern ||
      detailClass?.fillPattern === ChartPattern.None ||
      detailClass?.fillPattern === ChartPattern.Undefined
    )
      return;

    const imgPattern = pattern.draw(
      detailClass.fillPattern as Exclude<ChartPattern, ChartPattern.None | ChartPattern.Undefined>,
      colorUtils.hexToRGB(detailClass.fillColor, 1 - (detailClass.fillTransparency || 0)),
    );

    const size = 20;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = size;
    canvas.height = size;

    ctx.fillStyle = imgPattern;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    map[`pattern_${source.id}`] = canvas.toDataURL();
  });

  return map;
};

export const buildLayersImagery = (sources: ResultSource[]): mapboxgl.AnyLayer[] => {
  return (
    sources
      ?.filter((source) => source.data.type === 'raster')
      ?.map((source) => {
        return {
          id: source.id,
          type: 'raster',
          source: source.id,
        };
      }) || []
  );
};

export const buildLayersFill = (sources: ResultSource[], result: IResult): mapboxgl.AnyLayer[] => {
  return (
    sources
      ?.filter((source) => source.data.type !== 'raster')
      ?.map((source) => {
        const [resultId, , value] = source.id.split('_');
        const layerResult = result.layers.find((layer) => layer.resultId === resultId);

        const detailClass = layerResult?.details?.classes?.find(
          (c) => c.category.toString().replace(/\s/g, '-') === value,
        );

        if (!detailClass) return;

        const fillPattern =
          detailClass?.fillPattern &&
          detailClass?.fillPattern !== ChartPattern.None &&
          detailClass?.fillPattern !== ChartPattern.Undefined
            ? {
                'fill-pattern': `pattern_${source.id}`,
              }
            : null;

        const fillColor = {
          'fill-color': detailClass?.fillColor,
          'fill-opacity': 1 - (detailClass?.fillTransparency || 0),
          'fill-outline-color': detailClass?.edgeColor,
          'fill-antialias': true,
        };

        return {
          id: source.id,
          type: 'fill',
          source: source.id,
          layout: {},
          paint: fillPattern || fillColor,
        };
      }) || []
  );
};

export const buildLayersOutline = (sources: ResultSource[], result: IResult): mapboxgl.AnyLayer[] => {
  return (
    sources
      ?.filter((source) => source.data.type !== 'raster')
      .map((source) => {
        const [resultId, , value] = source.id.split('_');
        const layerResult = result.layers.find((layer) => layer.resultId === resultId);

        const detailClass = layerResult?.details?.classes?.find(
          (c) => c.category.toString().replace(/\s/g, '-') === value,
        );

        if (!detailClass) return;

        return {
          id: `${source.id}_outline`,
          type: 'line',
          source: source.id,
          layout: {},
          paint: {
            'line-color': detailClass?.edgeColor,
            'line-width': detailClass?.edgeThickness,
            'line-opacity': 1 - (detailClass?.edgeTransparency || 0),
          },
        };
      }) || []
  );
};

export const buildInspectorTool = (sources: ResultSource[], result: IResult) => {
  const inspectorDataMap = new Map<string, string>();

  sources.forEach((source) => {
    const [resultId, , value] = source.id.split('_');
    const layerResult = result.layers.find((layer) => layer.resultId === resultId);
    const detailClass = layerResult?.details?.classes?.find(
      (c) => c.category.toString().replace(/\s/g, '-') === value,
    );
    if (!detailClass) return;

    const columnsIndexToDisplay = layerResult.inspector.columnsToDisplay.map((column) => {
      return layerResult.details.infoColumns.findIndex((c) => c.displayName === column);
    });

    const innerHtml = `<div>
      <div style="display: flex; flex-direction: row;align-items: center;">
        <div style="width: 12px;
            height: 12px;
            text-align: center;
            margin-right: 8px;
            border-radius: 50%;
            background-color: ${detailClass.fillColor};
            opacity: ${1 - (detailClass.fillTransparency || 0)};
            border: solid ${detailClass.edgeThickness}px ${colorUtils.hexToRGB(
              detailClass.edgeColor,
              1 - (detailClass.edgeTransparency || 0),
            )};"
        ></div>
        ${detailClass.displayName}
      </div>
        ${detailClass.infoValues
          .filter((v, i) => columnsIndexToDisplay.includes(i))
          .map((v, i) => `${layerResult.details.infoColumns[i].displayName}: ${v}`)
          .join(' | ')}
    </div>`;

    inspectorDataMap.set(source.id, innerHtml);
  });

  return inspectorDataMap;
};
