import { FormControl, TextField, Typography } from '@mui/material';
import { useState } from 'react';

export interface ProjectDialogFormProps {
  AOIInfo;
  showError?: boolean;
  onProjectChange?: (projectName: string, projectDescription: string) => void;
  isInstantHabMap?: boolean;
}

export const ProjectDialogForm: React.FC<ProjectDialogFormProps> = ({
  AOIInfo,
  showError,
  onProjectChange,
  isInstantHabMap,
}) => {
  const [projectName, setProjectName] = useState<string>('');
  const [projectDescription, setProjectDescription] = useState<string>('');

  return (
    <FormControl
      id="project-form"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: 'var(--content-spacing-narrow)',
        justifyContent: 'space-evenly',
        alignContent: 'space-evenly',
        alignItems: 'center',
        textAlign: 'center',
        width: '100%',
        boxSizing: 'border-box',
      }}
    >
      {/* <Typography style={{ width: '100%', color: 'var(--gentian-dark-gray)' }}>Survey area</Typography> */}
      <img
        alt="AOI Thumbnail"
        src={AOIInfo.thumbnail}
        style={{
          maxHeight: '128px',
          maxWidth: '256px',
          border: '1px solid var(--gentian-light-gray)',
          boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.75)',
        }}
      />
      <Typography marginTop="4px" style={{ width: '100%', color: 'var(--gentian-dark-gray)' }}>
        Total Area: {AOIInfo.TotalArea} {AOIInfo.units}
      </Typography>
      <TextField
        id="outlined-basic"
        label="Project Name"
        variant="standard"
        fullWidth
        required
        value={projectName}
        error={showError && !projectName}
        onChange={(e) => {
          setProjectName(e.target.value);
          onProjectChange?.(e.target.value, projectDescription);
        }}
        sx={{
          margin: 'var(--content-spacing-narrow)',
          '& .MuiInput-input': {
            lineHeight: '1.75em',
            height: '1.75em',
          },
        }}
      />
      {!isInstantHabMap && (
        <TextField
          id="outlined-basic"
          label="Tell Us More"
          variant="standard"
          multiline
          rows={4}
          helperText="Explain your project in more detail."
          fullWidth
          onChange={(e) => {
            setProjectDescription(e.target.value);
            onProjectChange?.(projectName, e.target.value);
          }}
          value={projectDescription}
          sx={{
            margin: 'var(--content-spacing-narrow)',
          }}
        />
      )}
    </FormControl>
  );
};
