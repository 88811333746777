import { useContext, useEffect, useState } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { ProgressContext } from '../contexts/progress.context';
import { useNavigate } from 'react-router-dom';

interface ProgressData {
  processId: string;
  progress: number;
  resultUrl: string;
  status: 'processing' | 'completed' | 'failed';
  statusUrl: string;
  errorMessage: string;
}

const useProgress = () => {
  const navigate = useNavigate();
  const { sseUrl, setSseUrl, projectName, setProjectName, isProgressPopupOpen, setIsProgressPopupOpen } =
    useContext(ProgressContext);
  const [data, setData] = useState<ProgressData | null>(null); // Holds the SSE data
  const [retry, setRetry] = useState(0); // Tracks connection retries

  useEffect(() => {
    if (sseUrl) {
      setIsProgressPopupOpen(true);
    }
  }, []);

  useEffect(() => {
    if (!sseUrl) {
      setIsProgressPopupOpen(false);
      setData(null);
      return;
    }

    let eventSource;

    const connect = () => {
      eventSource = new EventSource(sseUrl);

      // Handle incoming messages
      eventSource.onmessage = (event) => {
        const parsedData: ProgressData = JSON.parse(event.data);
        if (parsedData.status === 'completed' || parsedData.status === 'failed') {
          if (parsedData.resultUrl) {
            parsedData.resultUrl = parsedData.resultUrl.replace('/result', '/survey-result');
          }

          eventSource.close(); // Close the connection on completion
        }
        // Fix url from response
        setData(parsedData);
        setRetry(0); // Reset retry count on successful message
      };

      // Handle errors
      eventSource.onerror = () => {
        datadogLogs.logger.error('Progress SSE: Connection error. Retrying...', {
          name: 'useProgress',
          retryCount: retry + 1,
          sseUrl,
        });

        // Close the connection and retry
        eventSource.close();
        const att = retry + 1;
        setRetry(att);
        if (att < 3) {
          // Retry connection after 3 seconds
          setTimeout(connect, 3000);
        } else {
          setSseUrl(null);
          datadogLogs.logger.error('Progress SSE: Connection error. Please try again later.', {
            name: 'useProgress',
            maxRetries: 3,
            sseUrl,
          });
        }
      };
    };

    // Starting the connection
    connect();

    return () => {
      // Closing SSE connection on component unmount
      eventSource?.close();
    };
  }, [sseUrl]);

  useEffect(() => {
    if (data?.status === 'completed' && data?.resultUrl) {
      // Redirect after 3 seconds to survey result page if progress popup is open
      const redirectTimeout = setTimeout(() => {
        if (isProgressPopupOpen) {
          navigate(data?.resultUrl);
          setIsProgressPopupOpen(false);
        }
      }, 3000);

      // Cleanup the timeout if the component unmounts or status changes
      return () => clearTimeout(redirectTimeout);
    }
  }, [data, isProgressPopupOpen]);

  return {
    data,
    sseUrl,
    setSseUrl,
    projectName,
    setProjectName,
    isProgressPopupOpen,
    setIsProgressPopupOpen,
  };
};

export default useProgress;
