import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  Drawer,
  Fab,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { useAuth0 } from '@auth0/auth0-react';
import useGentian from '../../core/hooks/useGentian';
import { jwtDecode } from 'jwt-decode';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/material/styles';
import { Res } from '../../resources';
import { AvatarComponent } from '../Avatar';
import { LogoComponent } from '../Logo';

export const HeaderComponent: React.FC = () => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const { loginWithRedirect, isAuthenticated, isLoading, getAccessTokenSilently, user, logout } = useAuth0();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [permissions, setPermissions] = useState<string[]>([]);
  const [permissionsLoaded, setPermissionsLoaded] = useState(false);
  const { feature_flags } = Res;

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [loginWithRedirect]);

  useEffect(() => {
    const getUserRoles = async () => {
      const token = await getAccessTokenSilently();
      const decodedToken = jwtDecode(token);
      const permissions = (decodedToken as any).permissions;
      setPermissions(permissions);
      setPermissionsLoaded(true);
    };

    getUserRoles();
  }, [getAccessTokenSilently]);

  const { mutate: updateAccount } = useGentian().updateAccount;
  useEffect(() => {
    const update = async () => {
      updateAccount(
        {
          ...user,
          user_metadata: {
            name: user.name || 'Unknown User',
            jobTitle: '',
            country: '',
            company: '',
            industry: '',
            phoneNumber: '',
            companySize: '',
            termsAgreed: false,
          },
        },
        {
          onSuccess: async () => {
            alert('Please verify your email address before continuing. Remember to check your spam folder.');
            localStorage.removeItem('gop-auth-token');
            await logout({ logoutParams: { returnTo: window.location.origin } });
          },
          onError: (error) => {
            console.error(error);
          },
        },
      );
    };

    if (isAuthenticated && user && !user.email_verified && localStorage.getItem('gop-auth-token')) {
      update();
    }
  }, [user, isAuthenticated, localStorage.getItem('gop-auth-token'), permissions]);

  useEffect(() => {
    if (!permissionsLoaded) {
      return;
    }
    const update = async () => {
      updateAccount(
        {
          ...user,
          user_metadata: {
            name: user.name || 'Unknown User',
            jobTitle: '',
            country: '',
            company: '',
            industry: '',
            phoneNumber: '',
            companySize: '',
            termsAgreed: false,
          },
        },
        {
          onSuccess: async () => {
            alert('We are setting up your account. Please try again in a 1 minute.');
            localStorage.removeItem('gop-auth-token');
            logout({ logoutParams: { returnTo: window.location.origin } });
          },
          onError: (error) => {
            console.error(error);
          },
        },
      );
    };

    if (isAuthenticated && user && user.email_verified && !permissions.includes('create:projects')) {
      update();
    }
  }, [user, isAuthenticated, permissions, permissionsLoaded]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const isSurveyRequestPage = pathname === '/survey-request';

  const headerLinkStyle = {
    color: 'var(--gentian-white)',
    outline: '1px solid var(--gentian-primary)',
    outlineOffset: '5px',
    borderRadius: '3px',
    transition: 'outline-color 0.2s ease-in-out',
    ':focus, :hover': {
      outlineColor: '#ffffff66',
    },
  };

  const drawerLinkStyle = {
    transition: 'background-color 0.2s ease-in-out',
    ':focus, :hover': {
      color: 'var(--gentian-white)',
      backgroundColor: 'var(--gentian-secondary)',
    },
  };

  return (
    <AppBar>
      <Toolbar sx={{ gap: '32px', justifyContent: { xs: 'space-between', sm: 'inherit' } }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ display: { sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          disableScrollLock
          sx={{ display: { sm: 'none' } }}
        >
          <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
              Menu
            </Typography>
            <List>
              {feature_flags.ENABLE_DASHBOARD && (
                <ListItem component={RouterLink} to="/dashboard" sx={drawerLinkStyle}>
                  <ListItemText primary="My Requests" />
                </ListItem>
              )}
              {feature_flags.ENABLE_PROJECTS && (
                <ListItem component={RouterLink} to="/projects" sx={drawerLinkStyle}>
                  <ListItemText primary="My Projects" />
                </ListItem>
              )}
              {feature_flags.ENABLE_RESULT_UPLOAD && permissions.includes('create:results') && (
                <ListItem component={RouterLink} to="/result-upload" sx={drawerLinkStyle}>
                  <ListItemText primary="Configure Survey Result" />
                </ListItem>
              )}
              {feature_flags.ENABLE_PRODUCT_MANAGEMENT && permissions.includes('create:products') && (
                <ListItem component={RouterLink} to="/products" sx={drawerLinkStyle}>
                  <ListItemText primary="Manage products" />
                </ListItem>
              )}
              {feature_flags.ENABLE_PRICING_INFO && (
                <ListItem component={RouterLink} to="/pricing" sx={drawerLinkStyle}>
                  <ListItemText primary="Pricing" />
                </ListItem>
              )}
            </List>
          </Box>
        </Drawer>
        <Link component={RouterLink} to="/" color={theme.palette.common.white} height="45px">
          <LogoComponent />
        </Link>
        <Box alignItems="center" gap="32px" sx={{ display: { xs: 'none', sm: 'flex' } }} flex={1}>
          {feature_flags.ENABLE_DASHBOARD && (
            <Link component={RouterLink} to="/dashboard" sx={headerLinkStyle}>
              <Typography>My Requests</Typography>
            </Link>
          )}
          {feature_flags.ENABLE_PROJECTS && (
            <Link component={RouterLink} to="/projects" sx={headerLinkStyle}>
              <Typography>My Projects</Typography>
            </Link>
          )}
          {feature_flags.ENABLE_RESULT_UPLOAD && permissions.includes('create:results') && (
            <Link component={RouterLink} to="/result-upload" sx={headerLinkStyle}>
              <Typography style={{ minWidth: '102px' }}>Configure Survey Result</Typography>
            </Link>
          )}
          {feature_flags.ENABLE_PRODUCT_MANAGEMENT && permissions.includes('create:results') && (
            <Link component={RouterLink} to="/products" sx={headerLinkStyle}>
              <Typography>Manage Products</Typography>
            </Link>
          )}
          {feature_flags.ENABLE_PRICING_INFO && (
            <Link component={RouterLink} to="/pricing" sx={headerLinkStyle}>
              <Typography>Pricing</Typography>
            </Link>
          )}
        </Box>
        <Box display="flex" alignItems="center" gap="16px">
          {!isSurveyRequestPage && (
            <>
              <Fab
                color="primary"
                aria-label="add"
                sx={{ position: 'fixed', bottom: 16, right: 16, display: { sm: 'none' } }}
                onClick={() => (window.location.href = '/survey-request')}
              >
                <AddIcon />
              </Fab>
              <Button
                variant="contained"
                component={RouterLink}
                to="/survey-request"
                sx={{ flex: 1, minWidth: '105px', textAlign: 'center', display: { xs: 'none', sm: 'flex' } }}
              >
                Request a Survey
              </Button>
            </>
          )}
          <AvatarComponent />
        </Box>
      </Toolbar>
    </AppBar>
  );
};
