import React from 'react';
import { Button, DialogActions, Tooltip, useMediaQuery, useTheme } from '@mui/material';

export interface ProjectDialogFooterProps {
  onClose: () => void;
  onContinue: () => void;
  onBuyNow: () => void;
  disabled: boolean;
  text: string;
  isInstantHabMap: boolean;
}

export const ProjectDialogFooter: React.FC<ProjectDialogFooterProps> = ({
  onClose,
  onContinue,
  onBuyNow,
  disabled,
  text,
  isInstantHabMap,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <DialogActions sx={{ p: 0, gap: '8px' }}>
      <Button variant="outlined" color="primary" onClick={onClose}>
        Cancel
      </Button>
      {isInstantHabMap ? (
        <Button
          id="map-checkout-button"
          variant="contained"
          onClick={onBuyNow}
          disabled={disabled}
          sx={{ textWrap: 'nowrap' }}
        >
          BUY NOW
        </Button>
      ) : (
        <Tooltip title={text}>
          <Button
            variant="contained"
            color="primary"
            disabled={disabled}
            onClick={onContinue}
            sx={{ textWrap: 'nowrap' }}
          >
            {isMobile ? 'Submit' : 'Submit NOW'}
          </Button>
        </Tooltip>
      )}
    </DialogActions>
  );
};
