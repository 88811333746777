import React, { useEffect, useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Snackbar,
  Alert,
} from '@mui/material';
import { countries } from './resources/countries';
import { useAuth0 } from '@auth0/auth0-react';
import useGentian from '../../core/hooks/useGentian';
import { IndustrySelectComponent } from './IndustrySelect';
import { datadogLogs } from '@datadog/browser-logs';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  outline: 'none',
};

const inputFieldStyle = {
  '& .MuiInputBase-root': {
    height: 32,
  },
};

export const OnboardingComponent = ({ open, handleClose }) => {
  let { user } = useAuth0();
  const { mutate: updateAccount } = useGentian().updateAccount;

  const [customIndustry, setCustomIndustry] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState<'success' | 'error' | 'warning' | 'info' | undefined>(
    'success',
  );

  const [userData, setUserData] = useState({
    name: '',
    jobTitle: '',
    country: '',
    company: '',
    industry: '',
    phoneNumber: '',
    companySize: '',
    termsAgreed: false,
  });

  const handleChange = (event) => {
    if (event.target.name?.toLowerCase() === 'industry' && event.target.value?.toLowerCase() !== 'other') {
      setCustomIndustry('');
    }
    setUserData({ ...userData, [event.target.name]: event.target.value });
  };

  const handleCheckboxChange = (event) => {
    setUserData({ ...userData, termsAgreed: event.target.checked });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!userData.termsAgreed) {
      alert('You must agree to the terms and conditions.');
      return;
    }

    saveData();
  };

  const saveData = () => {
    user = {
      ...user,
      user_metadata: { ...userData },
    };

    try {
      updateAccount(user, {
        onSuccess: () => {
          setSnackbarMessage('Your account has been updated.');
          setSnackbarOpen(true);
          setSnackbarType('success');
          handleClose();
        },
        onError: (error) => {
          setSnackbarMessage(`Failed to update your account. Please try again later. ${error}`);
          setSnackbarOpen(true);
          setSnackbarType('error');
        },
      });
    } catch (e) {
      datadogLogs.logger.error('Error updating account', {
        error: e,
        name: 'saveData',
      });
      setSnackbarMessage(`Failed to update your account. Please try again later. ${e}`);
      setSnackbarOpen(true);
      setSnackbarType('error');
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    if (user) {
      setUserData({
        ...userData,
        name: userData.name || user?.name || '',
      });
    }
  }, [user]);

  return (
    <Modal open={open} onClose={handleClose} disableEscapeKeyDown>
      <form onSubmit={handleSubmit}>
        <Box sx={modalStyle} style={{ flexDirection: 'column' }}>
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Tell us a little about you
          </Typography>
          <Divider sx={{ mb: 2 }} />

          <Grid container spacing={2}>
            {/* NAME */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="What is your name"
                name="name"
                value={userData.name}
                variant="standard"
                onChange={handleChange}
                sx={inputFieldStyle}
                disabled={user?.sub?.startsWith('google')}
                helperText={
                  user?.sub?.startsWith('google')
                    ? 'Your name is automatically set from your Google account. It cannot be changed here.'
                    : undefined
                }
                required
              />
            </Grid>
            {/* JOB TITLE */}
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="What is your job title"
                name="jobTitle"
                value={userData.jobTitle}
                onChange={handleChange}
                variant="standard"
                sx={inputFieldStyle}
              />
            </Grid>
            {/* COUNTRY */}
            <Grid item xs={6}>
              <FormControl fullWidth variant="standard" sx={inputFieldStyle} required>
                <InputLabel>Country</InputLabel>
                <Select name="country" value={userData.country} label="Country" onChange={handleChange}>
                  {countries.map((country, index) => (
                    <MenuItem key={index} value={country}>
                      {country}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* COMPANY */}
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Your company"
                name="company"
                value={userData.company}
                variant="standard"
                onChange={handleChange}
                sx={inputFieldStyle}
                required
              />
            </Grid>
            {/* INDUSTRY */}
            <Grid item xs={6}>
              <IndustrySelectComponent
                value={userData.industry}
                onChange={handleChange}
                sx={inputFieldStyle}
                required
              />
            </Grid>

            {/* Conditional rendering for custom industry input */}
            {userData.industry?.toLowerCase() === 'other' && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Specify your industry"
                  name="customIndustry"
                  value={customIndustry}
                  variant="standard"
                  onChange={(e) => setCustomIndustry(e.target.value)}
                  sx={inputFieldStyle}
                />
              </Grid>
            )}
          </Grid>

          <FormControlLabel
            control={<Checkbox checked={userData.termsAgreed} onChange={handleCheckboxChange} required />}
            label={
              <React.Fragment>
                I agree to the <a href="#">terms and conditions</a>.
              </React.Fragment>
            }
            sx={{ mt: 2, mb: 2, display: 'flex', justifyContent: 'center' }}
          />

          <Divider sx={{ mb: 2 }} />
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <Button type="submit" variant="contained">
              SAVE
            </Button>
          </Box>
        </Box>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbarType} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </form>
    </Modal>
  );
};
