import React, { useState } from 'react';
import { Box, Dialog, Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import * as turf from '@turf/turf';
import { IProduct } from '../../core/interfaces/product.interface';
import { IProject } from '../../core/interfaces/project.interface';
import { IAOIInfo } from '../../core/interfaces/aoi-info.interface';
import InstantHabmapImg from '../../Assets/instanthabmap.jpg';
import AdvancedHabmapImg from '../../Assets/advancedhabmap.jpg';
import BespokeHabmapImg from '../../Assets/bespokehabmap.jpg';
import { ProjectDialog } from '../ProjectDialog';
import { Res } from '../../resources';
import ukBorders from '../../Assets/uk-borders.json';
import { Feature, MultiPolygon } from 'geojson';
import { ProductCard } from '../ProductCard';
import { BulletItem } from '../BulletItem';

const ukPolygon = turf.multiPolygon((ukBorders as Feature<MultiPolygon>).geometry.coordinates);

export interface ProductsListProps {
  products?: IProduct[];
  AOIInfo?: IAOIInfo;
  onClose: () => void;
  onSuccess?(project: IProject): void;
  onSelectProduct?: (product: IProduct) => void;
}

export const ProductsList: React.FC<ProductsListProps> = ({
  products,
  AOIInfo,
  onClose,
  onSuccess,
  onSelectProduct,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [selectedProduct, setSelectedProduct] = useState<IProduct>(undefined);
  const [showProjectNameDialog, setShowProjectNameDialog] = useState(false);

  const handleOpenNameProject = (productId: string) => {
    const findSelectedProduct = products?.find((p) => p._id === productId);
    onSelectProduct?.(findSelectedProduct);
    setSelectedProduct(findSelectedProduct);
    setShowProjectNameDialog(true);
  };

  const handleCloseNameProject = () => {
    setShowProjectNameDialog(false);
  };

  const handleCloseAll = () => {
    onClose();
    handleCloseNameProject();
  };

  const isAOIInUK = (aoiInfo: IAOIInfo) => {
    try {
      const feature = aoiInfo?.shape?.features[0];
      const geometryType = feature?.geometry?.type;

      if (!geometryType) {
        console.error('Missing AOI geometry type');
        return false;
      }

      let aoiPolygon: Feature<turf.Polygon>;
      if (geometryType === 'Polygon') {
        aoiPolygon = turf.polygon(feature.geometry.coordinates);
      } else if (geometryType === 'MultiPolygon') {
        aoiPolygon = turf.polygon(feature.geometry.coordinates[0]);
      }

      return turf.booleanWithin(aoiPolygon, ukPolygon);
    } catch (error) {
      console.error('Error checking if AOI is in UK:', error);
      return false;
    }
  };

  return (
    <Box
      display="flex"
      gap="16px"
      flexDirection={isMobile ? 'column' : 'row'}
      height={isMobile ? 'unset' : '600px'}
    >
      <ProductCard
        title="True Sight"
        img={InstantHabmapImg}
        description="Instant habitat mapping for rapid BNG assessments across the UK"
        onButtonClick={() => handleOpenNameProject(Res.ids.products.INSTANT_HAB_MAP)}
        link="https://gentian.io/true-sight"
        isOnlyAvailableInUK
        disabled={!isAOIInUK(AOIInfo)}
      >
        <BulletItem>Immediate results</BulletItem>
        <BulletItem>Results follow the statutory metric for biodiversity net gain (BNG)</BulletItem>
        <BulletItem>5-10 habitat classes (most common habitat types targeted for BNG estimate)</BulletItem>
        <BulletItem>Priority habitats included </BulletItem>
        <BulletItem>Linear features not included </BulletItem>
        <BulletItem>Based on imagery up to 3 years old for recent results </BulletItem>
        <BulletItem>£10-30 per hectare</BulletItem>
      </ProductCard>

      <Divider
        orientation={isMobile ? 'horizontal' : 'vertical'}
        flexItem
        sx={{ margin: isMobile ? '0 10px' : '10px 0' }}
      />

      <ProductCard
        title="True Sage"
        img={AdvancedHabmapImg}
        description="Advanced habitat mapping reviewed by experts for reliability"
        onButtonClick={() => handleOpenNameProject(Res.ids.products.ADVANCED_HAB_MAP)}
        link="https://gentian.io/true-sage"
        isRequest
      >
        <BulletItem>Delivery within 10 working days</BulletItem>
        <BulletItem>
          Results follow statutory metrics for BNG (UK), EUNIS (Europe) or IUCN (Global).
        </BulletItem>
        <BulletItem>
          10-30 habitat classes (nearly all classes in the relevant habitat metric targeted)
        </BulletItem>
        <BulletItem>Priority habitats included </BulletItem>
        <BulletItem>Linear features included </BulletItem>
        <BulletItem>Based on imagery less than 1 year old for the most up-to-date results. </BulletItem>
        <BulletItem>£50-100 per hectare</BulletItem>
      </ProductCard>

      <Divider
        orientation={isMobile ? 'horizontal' : 'vertical'}
        flexItem
        sx={{ margin: isMobile ? '0 10px' : '10px 0' }}
      />

      <ProductCard
        title="True Oracle"
        img={BespokeHabmapImg}
        description="Bespoke habitat mapping designed to meet your needs with expertly-tailored results"
        onButtonClick={() => handleOpenNameProject(Res.ids.products.BESPOKE_HAB_MAP)}
        link="https://gentian.io/true-oracle"
        isRequest
      >
        <Typography fontSize="inherit">
          Our Bespoke Habitat Mapping service is tailored for clients with specific habitat mapping needs.
        </Typography>
        <Typography fontSize="inherit">
          We collaborate with you to create high-resolution maps based on site-specific data, ensuring your
          project's requirements are fully captured.
        </Typography>
        <Typography fontSize="inherit">
          Whether it's for conservation, land development or environmental assessments, our custom solution
          offers unmatched precision and flexibility.
        </Typography>
      </ProductCard>

      {showProjectNameDialog && (
        <Dialog
          open={showProjectNameDialog}
          onClose={handleCloseNameProject}
          disableScrollLock
          maxWidth="xl"
          PaperProps={{
            sx: {
              margin: '0',
            },
          }}
        >
          <ProjectDialog
            AOIInfo={AOIInfo}
            onClose={handleCloseNameProject}
            onSuccess={onSuccess}
            handleCloseAll={handleCloseAll}
            product={selectedProduct}
          />
        </Dialog>
      )}
    </Box>
  );
};
