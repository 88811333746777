import './style.css';
import React, { useRef, useState } from 'react';
import { IProject } from '../../core/interfaces/project.interface';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DataGrid, GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import { DownloadAOIComponent } from '../DownloadAIO';
import { OpenAOIComponent } from '../OpenAOI';
import { Download, Edit, OpenInNew } from '@mui/icons-material';
import { ProjectUpdateModal } from '../ProjectUpdateModal';

export interface PendingProjectsProps {
  projects: IProject[];
  onRowSelect?: (project: IProject) => void;
  onEditProject?: (project: IProject) => void;
}

export const PendingProjectsComponent: React.FC<PendingProjectsProps> = ({
  projects,
  onRowSelect,
  onEditProject,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [searchText, setSearchText] = useState('');
  const [codeEditModalOpen, setCodeEditModalOpen] = useState(false);
  const [project, setProject] = useState<IProject>(undefined);

  const handleSearchChange = (event) => {
    setSearchText(event.target.value.toString());
  };

  const columns = useRef<GridColDef[]>();
  columns.current = [
    {
      field: 'id',
      headerName: 'Id',
      headerAlign: 'center',
    },
    {
      field: 'AOIThumbnail',
      headerAlign: 'center',
      headerName: '',
      sortable: false,
      groupable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <img
            src={params.value}
            alt="Project Thumbnail"
            style={{
              maxWidth: '80px',
              maxHeight: '42px',
              objectFit: 'cover',
              borderRadius: '4px',
            }}
          />
        </Box>
      ),
    },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 128,
      headerAlign: 'center',
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
          <Typography
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 3,
              textOverflow: 'ellipsis',
              whiteSpace: 'normal',
              height: 'fit-content',
              textWrap: 'wrap',
              fontSize: '12px',
            }}
          >
            {params.value}
          </Typography>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setProject(params.row);
              setCodeEditModalOpen(true);
            }}
          >
            <Tooltip title="Edit project name">
              <Edit />
            </Tooltip>
          </IconButton>
        </Box>
      ),
    },
    {
      field: 'code',
      headerName: 'Code',
      width: 96,
      headerAlign: 'center',
    },
    {
      field: 'requestDate',
      headerName: 'Request Date',
      maxWidth: 160,
      minWidth: 96,
      align: 'center',
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) => new Date(params.value).toLocaleDateString(),
      headerAlign: 'center',
    },
    {
      field: 'download',
      headerName: 'Download Boundary',
      width: 96,
      sortable: false,
      groupable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <DownloadAOIComponent AOI={params.value} variant="IconOnly" />
        </Box>
      ),
      headerAlign: 'center',
    },
    {
      field: 'open',
      headerName: 'Open Requests',
      sortable: false,
      groupable: false,
      filterable: false,
      hideable: false,
      width: 96,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <OpenAOIComponent AOI={params.value} variant="IconOnly" />
        </Box>
      ),
      headerAlign: 'center',
    },
    {
      field: 'result',
      headerName: 'Open Result',
      width: 180,
      renderCell: (params) =>
        params.row.hasResult ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Button
              variant="contained"
              onClick={() => (window.location.href = `/survey-result/${params.value}`)}
            >
              Open Result <OpenInNew />
            </Button>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>Pending</Box>
        ),
      headerAlign: 'center',
    },
    {
      field: 'downloadResult',
      headerName: 'Download Results',
      width: 220,
      renderCell: (params) =>
        params.value ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Button variant="contained" onClick={() => window.open(`${params.value}`, '_blank')}>
              Download Result <Download />
            </Button>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>Pending</Box>
        ),
      headerAlign: 'center',
    },
  ];

  const filteredRows = projects
    .map((r) => ({
      ...r,
      download: r.AOI,
      open: r.AOI,
      id: r._id,
      result: r._id,
      downloadResult: r.deliverableUrl,
    }))
    .filter(
      (row) =>
        row.name.includes(searchText) || new Date(row.requestDate).toLocaleDateString().includes(searchText),
    );

  return !isMobile ? (
    <Box>
      {codeEditModalOpen && (
        <ProjectUpdateModal
          field="name"
          project={project}
          onClose={() => setCodeEditModalOpen(false)}
          onSaveClick={(value) => {
            project.name = value;
            onEditProject?.(project);
            setCodeEditModalOpen(false);
          }}
        />
      )}
      <TextField
        value={searchText}
        onChange={handleSearchChange}
        placeholder="Search by name or date"
        variant="standard"
        sx={{ width: '256px', mt: 2 }}
      />

      <DataGrid
        className="pending-projects-table"
        rows={filteredRows}
        columns={columns.current}
        onRowClick={(params) => {
          const project = projects.find((p) => p._id === params.id);
          onRowSelect?.(project);
        }}
        disableColumnFilter
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
          sorting: {
            sortModel: [
              {
                field: 'requestDate',
                sort: 'desc',
              },
            ],
          },
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        pageSizeOptions={[5, 10, 20, 50, 100]}
        disableRowSelectionOnClick
      />
    </Box>
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {projects.map((project) => (
        <Box key={project._id} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Card
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              mt: 'var(--content-spacing-narrow)',
            }}
          >
            <CardHeader title={project.name} />
            <CardMedia image={project.AOIThumbnail} title={project.name} component="img" />
            <CardContent>
              <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                {`Request date: ${new Date(project.requestDate).toLocaleDateString()}`}
              </Box>
            </CardContent>
            <CardActions>
              <Box>
                <DownloadAOIComponent AOI={project.AOI} />
              </Box>
              <Box>
                <OpenAOIComponent AOI={project.AOI} />
              </Box>
              <Box>
                <Button
                  variant="contained"
                  onClick={() => (window.location.href = `/survey-result/${project._id}`)}
                >
                  Result <OpenInNew />
                </Button>
              </Box>
            </CardActions>
          </Card>
        </Box>
      ))}
    </Box>
  );
};
