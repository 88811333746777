import React, { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableSortLabel,
} from '@mui/material';
import { ContentTileComponent } from '../../Components/ContentTitle';
import { Data, Order } from '../../core/interfaces/usage.interface';
import useGentian from '../../core/hooks/useGentian';
import { useUserPermissions } from '../../core/hooks/useUserPermissions';

const MyDashboard = () => {
  const { useUsage, useAllProjects } = useGentian();
  const { permissions } = useUserPermissions(); // Use the useUserPermissions hook to get permissions
  const [paginatedRows, setPaginatedRows] = useState<Data[]>([]);

  const [start, setStart] = useState(0);
  const [order, setOrder] = useState<Order>({ by: 'orderDate', dir: 'desc' });
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);

  const {
    data: usageDataRaw,
    isLoading,
    isError,
    refetch,
  } = useUsage({
    start,
    limit: rowsPerPage,
    sort: order.by,
    sortDirection: order.dir,
  });

  const { data: projectsWithResult } = useAllProjects({ status: 'has-result' });
  const { data: projectsWithoutResult } = useAllProjects({ status: 'no-result' });

  const allProjects = React.useMemo(
    () => [...(projectsWithResult || []), ...(projectsWithoutResult || [])],
    [projectsWithResult, projectsWithoutResult],
  );

  useEffect(() => {
    if (usageDataRaw?.items?.length && allProjects.length) {
      const usageData = usageDataRaw.items;

      const rows: Data[] =
        usageData.map((item) => {
          // Find the corresponding project in allProjects
          const project = allProjects.find((project) => project._id === item.uuid);

          // Use customerName from the project if found, otherwise fallback to clientName
          const clientName = item.clientName || project?.customerName;

          // Calculate cost based on clientName
          const cost =
            clientName === 'Viability (Hesti)'
              ? calculateHestiCost(item.totalArea)
              : calculateCost(item.totalArea);

          return {
            id: item.uuid,
            product: item.product,
            clientName: clientName, // Use customerName here
            orderDate: item.orderDate,
            totalArea: item.totalArea,
            biodiversityUnits: item.biodiversityUnits,
            status: item.status,
            projectName: item.projectName ? item.projectName : item.uuid,
            cost: cost,
          };
        }) || [];

      const totalCount = usageDataRaw.totalCount;
      setTotalCount(totalCount);
      setPaginatedRows(rows);
    }
  }, [usageDataRaw, allProjects]);

  const handleRequestSort = (property: keyof Data) => {
    const isAsc = order.by === property && order.dir === 'asc';
    setOrder({ by: property, dir: isAsc ? 'desc' : 'asc' });
    refetch();
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    const nextStart = newPage * rowsPerPage;
    setStart(nextStart);
    setPage(newPage);
    refetch();
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10) || 0);
    setStart(0);
    setPage(0);
    refetch();
  };

  const createSortHandler = (property: keyof Data) => () => {
    handleRequestSort(property);
  };

  // returns cost based on totalArea in ££
  const calculateCost = (totalArea: number): number => {
    if (totalArea <= 0) return 0;
    if (totalArea <= 1) return 30;
    if (totalArea <= 30) return 29 + (totalArea - 1) * 15;
    return 30 + 29 * 15 + (totalArea - 30) * 15;
  };

  const calculateHestiCost = (totalArea: number): number => {
    if (totalArea <= 0) return 0;
    if (totalArea <= 1) return 8;
    return 8 * totalArea;
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Alert severity="error">Error fetching usage data</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: '20px', display: 'flex', flexDirection: 'column' }}>
      <ContentTileComponent className="hide-on-fullscreen">
        <Box sx={{ pl: 'var(--content-spacing-narrow)' }}>
          {permissions.includes('ADMIN:usage') ? 'Admin Dashboard' : 'My Dashboard'}
        </Box>
      </ContentTileComponent>
      <TableContainer component={Paper} sx={{ flexGrow: 1 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={order.by === 'id'}
                  direction={order.by === 'id' ? order.dir : 'asc'}
                  onClick={createSortHandler('id')}
                >
                  ID
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={order.by === 'product'}
                  direction={order.by === 'product' ? order.dir : 'asc'}
                  onClick={createSortHandler('product')}
                >
                  Product
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={order.by === 'product'}
                  direction={order.by === 'product' ? order.dir : 'asc'}
                  onClick={createSortHandler('product')}
                >
                  Project Name/UUID
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={order.by === 'clientName'}
                  direction={order.by === 'clientName' ? order.dir : 'asc'}
                  onClick={createSortHandler('clientName')}
                >
                  Client Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={order.by === 'orderDate'}
                  direction={order.by === 'orderDate' ? order.dir : 'asc'}
                  onClick={createSortHandler('orderDate')}
                >
                  Order Date
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={order.by === 'totalArea'}
                  direction={order.by === 'totalArea' ? order.dir : 'asc'}
                  onClick={createSortHandler('totalArea')}
                >
                  Total Area (ha)
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={order.by === 'biodiversityUnits'}
                  direction={order.by === 'biodiversityUnits' ? order.dir : 'asc'}
                  onClick={createSortHandler('biodiversityUnits')}
                >
                  Biodiversity Units
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={order.by === 'status'}
                  direction={order.by === 'status' ? order.dir : 'asc'}
                  onClick={createSortHandler('status')}
                >
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={order.by === 'cost'}
                  direction={order.by === 'cost' ? order.dir : 'asc'}
                  onClick={createSortHandler('cost')}
                >
                  Cost (£)
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRows.map((row) => (
              <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.id.toString().slice(-6)}
                </TableCell>
                <TableCell>{row.product}</TableCell>
                <TableCell>{row.projectName}</TableCell>
                <TableCell>{row.clientName}</TableCell>
                <TableCell>
                  {new Date(
                    row.orderDate._seconds * 1000 + row.orderDate._nanoseconds / 1e6,
                  ).toLocaleString()}
                </TableCell>
                <TableCell>{row.totalArea}</TableCell>
                <TableCell>{row.biodiversityUnits}</TableCell>
                <TableCell>{row.status}</TableCell>
                <TableCell>£ {row.cost.toFixed(2)}</TableCell> {/* Display cost with 2 decimal places */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        component="div"
        count={totalCount || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ mt: 2 }}
      />
    </Box>
  );
};

export default MyDashboard;
