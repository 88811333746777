import React from 'react';
import { Box, Typography } from '@mui/material';
import { Grass, Water, Nature } from '@mui/icons-material'; // Import Forest icon
import { IBiodiversityUnit } from '../../core/interfaces/BNG-units.interface';

interface ChartTabsProps {
  units: IBiodiversityUnit;
}

const BNGTabs: React.FC<ChartTabsProps> = ({ units }) => {
  return (
    <Box width="100%" marginBottom="8px">
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, width: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Nature sx={{ fontSize: 45, color: '#3599AF', marginTop: 1 }} /> {/* Larger Forest icon */}
          <Typography variant="h4" sx={{ marginTop: 2, fontWeight: 'bold' }}>
            {units?.habitatUnits}
          </Typography>
        </Box>
        <Typography variant="h6" sx={{ color: '#3599AF', textTransform: 'uppercase', mb: 1 }}>
          Habitat Units
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            gap: 8,
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 0.5 }}>
            <Typography variant="h6" sx={{ color: '#6BAA00', position: 'relative', zIndex: 1 }}>
              {units?.hedgerowUnits}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Grass sx={{ color: '#6BAA00' }} />
              <Typography variant="body1" sx={{ color: '#6BAA00' }}>
                Hedgerows
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 0.5 }}>
            <Typography variant="h6" sx={{ color: '#2196F3', position: 'relative', zIndex: 1 }}>
              {units?.watercourseUnits}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Water sx={{ color: '#2196F3' }} />
              <Typography variant="body1" sx={{ color: '#2196F3' }}>
                Watercourses
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BNGTabs;
