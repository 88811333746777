import { Box, IconButton, Switch, styled } from '@mui/material';
import { ProductComponent } from '../Product';
import { IProduct } from '../../core/interfaces/product.interface';
import { Edit } from '@mui/icons-material';

const ProductList = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  width: '100%',
});

export interface ProductListProps {
  products?: IProduct[];
  displayAdminControls?: boolean;
  onProductSelected?: (product: IProduct) => void;
  onProductDeselected?: (product: IProduct) => void;
  onSelectedForEdition?: (product: IProduct) => void;
  onSelectedForDeletion?: (product: IProduct) => void;
  onProductEnabledChange?: (product: IProduct) => void;
}

export const ProductListComponent: React.FC<ProductListProps> = ({
  products,
  onProductSelected,
  onProductDeselected,
  displayAdminControls,
  onSelectedForEdition,
  onProductEnabledChange,
}) => (
  <ProductList>
    {products
      ?.filter((product) => displayAdminControls || product.enabled)
      .map((product) => (
        <Box key={product._id} sx={{ margin: 'var(--content-spacing-narrow)' }}>
          {displayAdminControls && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: 'fit-content',
                  border: 'solid 1px lightgray',
                  borderRadius: '4px 4px 0 0',
                  boxShadow: '0 0 4px 0 lightgray',
                }}
              >
                <Switch
                  checked={product.enabled}
                  title="Enable/Disable Product"
                  onChange={(event) => {
                    product.enabled = event.target.checked;
                    onProductEnabledChange?.(product);
                  }}
                />
                <IconButton onClick={() => onSelectedForEdition?.(product)}>
                  <Edit />
                </IconButton>
              </Box>
            </Box>
          )}
          <ProductComponent
            key={product._id}
            product={product}
            onProductSelected={onProductSelected}
            onProductDeselected={onProductDeselected}
            sx={{
              minHeight: '196px',
            }}
          />
        </Box>
      ))}
  </ProductList>
);
