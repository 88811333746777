import './style.css';

import React from 'react';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import ChartTabs from './chart-tabs';
import LayerDetails from './details';
import { IResult } from '../../core/interfaces/result.interface';
import { IconButton, Switch, Tooltip } from '@mui/material';
import { Download, Share, SpeakerNotesOutlined } from '@mui/icons-material';
import ResultSharingComponent from '../ResultSharing';
import { IBiodiversityUnit } from '../../core/interfaces/BNG-units.interface';

interface LayersComponentProps {
  result: IResult;
  onCheckboxChange?: (checked: boolean, classItem: any) => void;
  onSelect?: (selected: boolean, layer: any) => void;
  notesColumnOpen?: boolean;
  setNotesColumnOpen?: (open: boolean) => void;
  units?: IBiodiversityUnit;
}

const LayersComponent: React.FC<LayersComponentProps> = ({
  result,
  onCheckboxChange,
  onSelect,
  notesColumnOpen,
  setNotesColumnOpen,
  units,
}) => {
  const { layers } = result || ({ layers: [] } as IResult);
  const [shareLinkModalOpen, setShareLinkModalModalOpen] = React.useState(false);

  const handleNotesColumnToggle = () => {
    if (setNotesColumnOpen) {
      setNotesColumnOpen(!notesColumnOpen);
    } else {
      console.error('setNotesColumnOpen is not defined');
    }
  };

  // Define the desired order for the className values
  const distinctivenessOrder = ['V.High', 'High', 'Medium', 'Low', 'V.Low'];

  // Sort the classes array for the Distinctiveness layer
  const sortedLayers = layers.map((layer) => {
    if (layer.tab?.displayName === 'Distinctiveness' && layer.details?.classes) {
      layer.details.classes.sort((a, b) => {
        return distinctivenessOrder.indexOf(a.category) - distinctivenessOrder.indexOf(b.category);
      });
    }
    return layer;
  });

  // Combine ChartTabs and BNGTabs data into a single array
  const combinedTabsData = [
    ...sortedLayers
      .filter((layer) => layer.tab?.visible)
      .map((layer) => ({ ...layer.tab, type: 'chart' as const })), // Chart tabs
    // ...sortedLayers
    //   .filter((layer) => layer.tab?.visible)
    //   .map((layer) => ({ ...layer.tab, type: 'bng' as const, displayName: 'BNG UNIT' })), // BNG tabs with custom label
  ];

  return (
    <Box
      sx={{
        maxWidth: '320px',
        minWidth: '320px',
        display: 'flex',
        flexDirection: 'column',
        bgcolor: '#fff',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Typography variant="body1" sx={{ p: '4px 16px 0 16px' }}>
          <b>{result?.project?.name}</b>
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <IconButton
            sx={{ mx: '16px', display: result?.status === 'published' ? 'block' : 'none' }}
            size="small"
            onClick={() => setShareLinkModalModalOpen(true)}
          >
            <Tooltip title="Share">
              <Share />
            </Tooltip>
          </IconButton>
          <IconButton
            sx={{ mx: '16px', display: result?.status === 'published' ? 'block' : 'none' }}
            size="small"
            onClick={handleNotesColumnToggle}
          >
            <Tooltip title="Notes">
              <SpeakerNotesOutlined />
            </Tooltip>
          </IconButton>
          {!!result?.deliverableUrl && (
            <IconButton
              size="small"
              sx={{ mx: '16px' }}
              onClick={() => window.open(result.deliverableUrl, '_blank')}
            >
              <Tooltip title="Download">
                <Download />
              </Tooltip>
            </IconButton>
          )}
        </Box>
        {shareLinkModalOpen && (
          <ResultSharingComponent
            link={`${window.location.origin}/survey-result/${result?.project?._id}`}
            onClose={() => setShareLinkModalModalOpen(false)}
            projectId={result?.project?._id}
            hasDownloadLink={!!result?.deliverableUrl}
          />
        )}
      </Box>
      {/* Combined Tabs */}
      <ChartTabs chartData={combinedTabsData} units={units} />
      {sortedLayers.map(
        (layer, index) =>
          layer.details.visible && (
            <Accordion key={index} sx={{}}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
                sx={{ '& .MuiAccordionSummary-content': { margin: '0px' } }}
              >
                <Switch
                  checked={layer.details.defaultEnabled !== false} // also check for undefined for retrocompatibility
                  onClick={(e) => {
                    e.stopPropagation();
                    const checked = (e.target as any).checked;
                    onSelect?.(checked, layer);
                    layer.details.defaultEnabled = checked;
                  }}
                ></Switch>
                <Typography sx={{ lineHeight: '38px' }}>{layer?.displayName}</Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{ m: 0, pl: 1 }}
                hidden={layer.details.defaultEnabled === false || !layer?.details?.classes?.length} // also check for undefined for retrocompatibility
              >
                <LayerDetails layer={layer} onCheckboxChange={onCheckboxChange} />
              </AccordionDetails>
            </Accordion>
          ),
      )}
    </Box>
  );
};

export default LayersComponent;
