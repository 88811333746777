// src/Components/GentainToken/index.tsx
import React from 'react';
import GentianTokenSVG from '../../Assets/Gentian_Token.svg'; // Adjust the path as necessary

interface GentainTokenProps {
  height?: string;
  width?: string;
}

const GentainTokenComponent: React.FC<GentainTokenProps> = ({ height = '24px', width = '24px' }) => {
  return <img src={GentianTokenSVG} alt="Gentian Token" style={{ marginRight: '10px', height, width }} />;
};

export default GentainTokenComponent;
