import React, { useState } from 'react';

import { Alert, Box, CircularProgress, Snackbar, TextField, Typography } from '@mui/material';
import { ContentTileComponent } from '../../Components/ContentTitle';
import useGentian from '../../core/hooks/useGentian';
import { IProject } from '../../core/interfaces/project.interface';
import { PendingProjectsCardsComponent } from '../../Components/PendingProjectCards';

export const ProjectsPage = () => {
  const { useProjects, updateProjectName } = useGentian();

  const { data: projects, isLoading, refetch } = useProjects();
  const [isSaving, setIsSaving] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [searchText, setSearchText] = useState('');

  const [snackbarType, setSnackbarType] = React.useState<
    'success' | 'error' | 'warning' | 'info' | undefined
  >('success');

  const handleProjectEdit = async (project: IProject) => {
    setIsSaving(true);

    await updateProjectName.mutate(
      { projectId: project._id, name: project.name },
      {
        onSuccess: async () => {
          await refetch();
          setIsSaving(false);
          setSnackbarMessage('Project name changed successfully');
          setSnackbarType('success');
          setSnackbarOpen(true);
        },
        onError: (error) => {
          setIsSaving(false);
          console.error(error);

          setSnackbarMessage((error as Error).message);
          setSnackbarType('error');
          setSnackbarOpen(true);
        },
      },
    );
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value.toString());
  };

  const filteredProjects =
    projects?.filter(
      (project) =>
        project.name.toLowerCase().includes(searchText.toLowerCase()) ||
        new Date(project.requestDate).toLocaleDateString().toLowerCase().includes(searchText.toLowerCase()),
    ) || [];

  return isLoading || isSaving ? (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
      <Typography variant="body1">
        {isLoading ? `Loading project list...` : `Saving project name...`}
      </Typography>
    </Box>
  ) : (
    <Box>
      <ContentTileComponent className="hide-on-fullscreen">
        <Box sx={{ pl: 'var(--content-spacing-narrow)' }}>My Projects</Box>
      </ContentTileComponent>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          alignItems: 'flex-start',
        }}
      >
        <Box sx={{ width: '80vw', marginBottom: 2, marginLeft: 'var(--content-spacing-narrow)' }}>
          <TextField
            value={searchText}
            onChange={handleSearchChange}
            placeholder="Search by name or date"
            variant="standard"
            sx={{ width: '256px', mt: 2 }}
          />
        </Box>
        <Box sx={{ width: '80vw', marginLeft: 'var(--content-spacing-narrow)' }}>
          <PendingProjectsCardsComponent projects={filteredProjects} onEditProject={handleProjectEdit} />
        </Box>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={snackbarType === 'success' ? 2000 : 6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarType} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};
