import React from 'react';
import { Menu, MenuItem, ListItemIcon, Typography, Link } from '@mui/material';
import { Logout, SpaceDashboard } from '@mui/icons-material';
import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient } from 'react-query';
import { datadogLogs } from '@datadog/browser-logs';

export interface AvatarMenuProps {
  anchorEl: null | HTMLElement;
  open: boolean;
  onClose: () => void;
}

export const AvatarMenuComponent: React.FC<AvatarMenuProps> = ({ anchorEl, open, onClose }) => {
  const queryClient = useQueryClient();
  const { logout } = useAuth0();

  const handleClose = () => {
    onClose();
  };

  const handleLogout = async () => {
    try {
      datadogLogs.logger.info('User decided to log out...');
      datadogLogs.logger.info('Cancelling all queries and mutations...');
      await queryClient.cancelQueries();
      await queryClient.cancelMutations();
      datadogLogs.logger.info('Logging out...');

      await logout({ logoutParams: { returnTo: window.location.origin } });
      datadogLogs.logger.info('Removing auth token from local storage...');
      localStorage.removeItem('gop-auth-token');
    } catch (error) {
      datadogLogs.logger.error('Error during logout process', { error });
      // Still attempt to remove the token even if other logout steps fail
      localStorage.removeItem('gop-auth-token');
    }
  };

  return (
    <Menu
      id="account-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      keepMounted
      disableScrollLock
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
            '.MuiMenu-list': {
              width: 200,
            },
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {/* <MenuItem onClick={() => handleMenuClick(MenuClickTarget.PROFILE)}>
        <ListItemIcon>
          <AccountCircle fontSize="small" />
        </ListItemIcon>
        Profile
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => handleMenuClick(MenuClickTarget.SETTINGS)}>
        <ListItemIcon>
          <Settings fontSize="small" />
        </ListItemIcon>
        Settings
      </MenuItem> */}
      <MenuItem>
        <ListItemIcon>
          <SpaceDashboard fontSize="small" />
        </ListItemIcon>
        <Link href="/mydashboard" color="inherit">
          <Typography variant="body2" color="text.primary">
            My Dashboard
          </Typography>
        </Link>
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        <Typography variant="body2" color="text.primary">
          Logout
        </Typography>
      </MenuItem>
      {/* <MenuItem onClick={() => console.log('wallet')}>
        <ListItemIcon>
          <Wallet fontSize="small" />
        </ListItemIcon>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Typography variant="body1">Wallet</Typography>
        </Box>
      </MenuItem>
      <MenuItem
        sx={{
          '&:hover': {
            backgroundColor: 'unset !important',
            cursor: 'auto',
          },
        }}
      >
        Balance
      </MenuItem>
      <MenuItem
        sx={{
          justifyContent: 'center',
          '&:hover': {
            backgroundColor: 'unset !important',
            cursor: 'auto',
          },
        }}
      >
        <GentainTokenComponent /> 0 Gentian Tokens
      </MenuItem>
      <MenuItem
        sx={{
          justifyContent: 'center',
          '&:hover': {
            backgroundColor: 'unset !important',
            cursor: 'auto',
          },
        }}
      >
        <Box>
          <Link href="/pricing/token_info">
            <Button variant="contained" color="primary" size="small">
              TOP UP
            </Button>
          </Link>
        </Box>
      </MenuItem> */}
    </Menu>
  );
};
