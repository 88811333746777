import React from 'react';
import ReactDOM from 'react-dom/client';
import './Styles/variables.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import { Res } from './resources';
import { datadogLogs } from '@datadog/browser-logs';

datadogLogs.init({
  clientToken: Res.api.datadog.CLIENT_TOKEN,
  site: 'datadoghq.eu',
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  env: Res.env,
  service: 'platform-frontend',
  forwardConsoleLogs: 'all',
  silentMultipleInit: true,
  beforeSend: (log) => {
    if (log.http && log.http.url.includes('mapbox')) {
      //abort
      return false;
    }
    return true;
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Auth0Provider
    domain={Res.api.auth0.DOMAIN}
    clientId={Res.api.auth0.CLIENT_ID}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: Res.api.auth0.AUDIENCE,
    }}
  >
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Auth0Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Logs a message to Datadog to indicate a new navigation started, logging a few user information, so we can track the user journey and the platform usage.
datadogLogs.logger.info('Navigation started');
