export const Res = {
  env: process.env.REACT_APP_ENV || 'local',
  ids: {
    products: {
      INSTANT_HAB_MAP: process.env.REACT_APP_INSTANT_HAB_MAP_ID || '6633834226509dc608a2ae4f',
      ADVANCED_HAB_MAP: process.env.REACT_APP_ADVANCED_HAB_MAP_ID || '66f4611b93cac60f7686ae24',
      BESPOKE_HAB_MAP: process.env.REACT_APP_BESPOKE_HAB_MAP_ID || '67767eba1667026962d34623',
    },
  },
  components: {
    maps: {
      MAPBOX_TOKEN:
        'pk.eyJ1IjoiY2hhcmxlc2ZvcnRlcyIsImEiOiJjbG81bmhya2YwMjh1MmtvMzZ4Y3p3NmhmIn0.DRiJTB8yqCfusiZP7n2QSg',
      GOOGLE_TOKEN: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || 'AIzaSyB7f5e74c1hS56KktrDLOSYMy98j7bRZYg',
    },
  },
  api: {
    gentian: {
      BFF_BASE_URL: process.env.REACT_APP_BFF_BASE_URL || 'http://localhost:3001',
    },
    auth0: {
      DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN || 'dev-gentian.eu.auth0.com',
      CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID || 'Pbm099oF4Un5fEA1H7NKmM5MCArrQyUE',
      AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE || 'https://api.gop.gentian.com/',
    },
    datadog: {
      CLIENT_TOKEN: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || 'pub7ba59bf4844fec236e8d7f0a705ddfe4',
    },
    stripe: {
      PUBLISHABLE_KEY:
        process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ||
        'pk_test_51P6BNzS55R29SXrDKWTtRluLdud15VmNcInxey8NQdDCzmS7kLYwD6G13sCC4451caFiYkouxJBQtvUUMMeao8YY00NCt5BwpV',
    },
  },
  feature_flags: {
    ENABLE_DASHBOARD: process.env.REACT_APP_FEATURE_DASHBOARD === 'true',
    ENABLE_MY_DASHBOARD: process.env.REACT_APP_FEATURE_MY_DASHBOARD === 'true',
    ENABLE_PROJECTS: process.env.REACT_APP_FEATURE_PROJECTS === 'true',
    ENABLE_VIEW_RESULTS: process.env.REACT_APP_FEATURE_VIEW_RESULTS === 'true',
    ENABLE_RESULT_UPLOAD: process.env.REACT_APP_FEATURE_RESULT_UPLOAD === 'true',
    ENABLE_PRODUCT_MANAGEMENT: process.env.REACT_APP_FEATURE_PRODUCT_MANAGEMENT === 'true',
    ENABLE_PRICING_INFO: process.env.REACT_APP_FEATURE_PRICING_INFO === 'true',
    ENABLE_PRICING_TOKEN_INFO: process.env.REACT_APP_FEATURE_PRICING_TOKEN_INFO === 'true',
  },
};
