import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Modal,
  Divider,
  useMediaQuery,
  useTheme,
  Checkbox,
  LinearProgress,
} from '@mui/material';
import useGentian from '../../core/hooks/useGentian';

export interface ResultSharingComponentProps {
  link: string;
  projectId: string;
  hasDownloadLink: boolean;
  onClose: () => void;
}
const ResultSharingComponent = ({
  link,
  onClose,
  projectId,
  hasDownloadLink,
}: ResultSharingComponentProps) => {
  const [copied, setCopied] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [shareResultsArtifacts, setShareResultsArtifacts] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const { setResultsPermissions } = useGentian();
  const { mutate: setPermissions, isLoading } = setResultsPermissions;

  const handleOnChangePermissions = (checked: boolean) => {
    setPermissions(
      {
        shareResultsArtifacts: checked,
        projectId,
      },
      {
        onSuccess: () => {
          setErrorMessage('');
        },
        onError: (error) => {
          setShareResultsArtifacts(!shareResultsArtifacts);
          setErrorMessage('Failed to update permissions. if the issue persists, please contact support.');
          console.error(error);
        },
      },
    );
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(link).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <Modal open onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: isMobile ? '90vw' : '600px',
          height: isMobile ? '90vh' : 'auto',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" component="h2">
          Result Sharing
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Typography sx={{ mt: 2 }}>Anyone with this link will be able to access the result:</Typography>
        {hasDownloadLink && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              checked={shareResultsArtifacts}
              disabled={isLoading}
              onChange={(_, checked) => {
                setShareResultsArtifacts(checked);
                handleOnChangePermissions(checked);
              }}
            />
            Allow result download on sharing
          </Box>
        )}
        {!!errorMessage && <Typography color="error">{errorMessage}</Typography>}
        {isLoading && (
          <>
            <LinearProgress sx={{ my: 2 }} />{' '}
            <Typography variant="caption" color="textSecondary">
              Updating permissions...
            </Typography>
          </>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            my: 2,
          }}
        >
          <TextField
            fullWidth
            variant="outlined"
            value={link}
            disabled={isLoading}
            InputProps={{
              readOnly: true,
            }}
            sx={{ mr: 2 }}
          />
          <Button variant="contained" onClick={copyToClipboard} disabled={isLoading}>
            {copied ? 'Copied!' : 'Copy'}
          </Button>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={onClose} disabled={isLoading}>
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ResultSharingComponent;
