import { Box } from '@mui/material';

export interface LoadingEllipsisProps {
  isLoading: boolean;
}

export const LoadingEllipsis: React.FC<LoadingEllipsisProps> = ({ isLoading }) => (
  <Box
    component="span"
    sx={{
      display: 'inline-block',
      '&::after': {
        content: '""',
        display: 'inline-block',
        width: '0.5em',
        animation: isLoading ? 'ellipsis 1.5s infinite' : 'none',
      },
      '@keyframes ellipsis': {
        '0%': { content: '""' },
        '25%': { content: '"."' },
        '50%': { content: '".."' },
        '75%': { content: '"..."' },
        '100%': { content: '""' },
      },
    }}
  />
);
