import gentianLogo from '../../Assets/GentianLogo_2022_White.png';

export const LogoComponent = () => (
  <img
    src={gentianLogo}
    alt="Gentian logo"
    style={{ height: '45px', width: '100%', minWidth: '100px', objectFit: 'contain' }}
    loading="lazy"
  />
);
