import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Snackbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { datadogLogs } from '@datadog/browser-logs';
import CryptoJS from 'crypto-js';
import { IProduct } from '../../core/interfaces/product.interface';
import { ProjectDialogFooter } from './ProjectDialogFooter';
import { ProjectDialogForm } from './ProjectDialogForm';
import { ConfirmationDialog } from '../ConfirmationDialog';
import { IProject } from '../../core/interfaces/project.interface';
import { IAOIInfo } from '../../core/interfaces/aoi-info.interface';
import useGentian from '../../core/hooks/useGentian';
import { useQueryClient } from 'react-query';
import useProgress from '../../core/hooks/useProgress';
import { Res } from '../../resources';

const stripePromise = loadStripe(Res.api.stripe.PUBLISHABLE_KEY);

export interface ProjectDialogProps {
  AOIInfo?: IAOIInfo;
  onClose?: () => void;
  onSuccess?(project: IProject): void;
  product?: IProduct;
  handleCloseAll: () => void;
}

export const ProjectDialog: React.FC<ProjectDialogProps> = ({
  AOIInfo,
  onClose,
  onSuccess,
  product,
  handleCloseAll,
}) => {
  const queryClient = useQueryClient();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { setSseUrl, setProjectName: setProgressProjectName, setIsProgressPopupOpen } = useProgress();

  // Initialize state with passed selected products
  const [projectName, setProjectName] = useState<string>('');
  const [projectDescription, setProjectDescription] = useState<string>('');
  const [projectInfo, setProjectInfo] = useState<IProject>();
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const { mutate: createInstantHabMap, isLoading: isCreatingInstantHabMap } =
    useGentian().createInstantHabmap;
  const { mutate: createProject } = useGentian().createProject;
  const { data: accountData } = useGentian().useAccount();
  const {
    mutate: createCheckoutSession,
    error: checkoutError,
    data: checkoutData,
  } = useGentian().createCheckoutSession;
  const account = accountData?.data;
  const { data: paymentStatusData } = useGentian().usePaymentStatus(checkoutData?.sessionId);

  const getTooltipText = () => {
    return !projectName ? 'Please enter a project name to continue' : `Request a survey for ${product?.name}`;
  };

  const getProjectInfo = () => {
    const project = {
      name: projectName,
      description: projectDescription,
      AOI: AOIInfo?.shape,
      products: [{ name: product?.name, id: product?._id }],
      totalArea: AOIInfo?.TotalArea,
      unit: AOIInfo?.units,
      AOIThumbnail: AOIInfo?.thumbnail,
      customerName: account?.name || account?.user_metadata?.name || account?.email || 'Anonymous',
      email: account?.email || 'Anonymous',
      company: account?.user_metadata?.company || 'Anonymous',
      jobTitle: account?.user_metadata?.jobTitle || 'Anonymous',
      country: account?.user_metadata?.country || 'Anonymous',
      projectHash: undefined,
    };

    const jsonString = JSON.stringify(project);
    const projectHash = CryptoJS.SHA256(jsonString).toString(CryptoJS.enc.Hex);
    project.projectHash = projectHash;
    return project;
  };

  const handleSubmitProject = () => {
    const project = getProjectInfo();
    createProject(
      { ...project },
      {
        onSuccess: () => {
          queryClient.invalidateQueries('projects');
          setShowConfirmation(false);
          onSuccess(project);
        },
        onError: (error) => {
          setSnackbarMessage(`Failed to create project. Please try again later. ${error}`);
          setSnackbarOpen(true);
        },
      },
    );
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const submitInstantHabMapProject = (projectInfo: IProject) => {
    createInstantHabMap(projectInfo, {
      onSuccess: (data) => {
        const token = localStorage.getItem('gop-auth-token');
        const body = token.split('.')[1];
        const decodedPayload = JSON.parse(Buffer.from(body, 'base64').toString('utf-8'));
        const clientId = decodedPayload.azp;

        const sseURL = `${Res.api.gentian.BFF_BASE_URL}/instant-hab-map/${data.projectId}/${clientId}/progress`;
        // Start SSE connection for project processing tracking
        setSseUrl(sseURL);
        setIsProgressPopupOpen(true);
        handleCloseAll();
      },
      onError: (data) => {
        setSnackbarMessage(`Error on submitting Instant HabMap Project`);
        datadogLogs.logger.error('Error on submitting Instant HabMap Project', {
          error: data,
          project: projectInfo,
        });
      },
    });
  };

  const handleOnBuyNow = () => {
    const newProjectInfo = getProjectInfo();
    setProjectInfo(newProjectInfo);
    // # 1 - Clear past checkout session if any and start a new one
    queryClient.invalidateQueries(['checkout-session']);
    createCheckoutSession(newProjectInfo);
  };

  useEffect(() => {
    if (checkoutError) {
      setSnackbarMessage(`Error on processing the checkout. ${checkoutError}`);
      datadogLogs.logger.error(`Error on processing the checkout. ${checkoutError}`, {
        error: checkoutError,
        account: {
          sub: accountData.data.sub,
          company: accountData.data.user_metadata['company'],
        },
        product: { id: product?._id, name: product?.name },
        project: {
          name: projectInfo?.name,
          totalArea: projectInfo?.totalArea,
        },
      });
      setProjectInfo(undefined);
    }
  }, [checkoutError]);

  useEffect(() => {
    // # 2 - If payment success: end checkout session and create project
    if (
      projectInfo &&
      paymentStatusData?.status === 'complete' &&
      paymentStatusData?.paymentStatus === 'paid'
    ) {
      submitInstantHabMapProject(projectInfo);
      queryClient.invalidateQueries(['checkout-session']);
    }
  }, [paymentStatusData, projectInfo]);

  return (
    <>
      <DialogTitle component="div">
        <Typography variant="h5" color="var(--gentian-dark-gray)">
          {product?.name}
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          top: 12,
          right: 12,
        }}
      >
        <Close />
      </IconButton>

      <Divider sx={{ marginX: '16px' }} />

      <DialogContent
        sx={{
          minWidth: isMobile ? '100vw' : '480px',
          backgroundColor: 'white',
          padding: 'var(--content-spacing-narrow)',
        }}
      >
        {!!checkoutData?.clientSecret && paymentStatusData?.status !== 'complete' ? (
          <Box
            sx={{
              boxSizing: 'border-box',
              minHeight: '680.438px',
              margin: '-16px',
              paddingBottom: '16px',
            }}
          >
            <EmbeddedCheckoutProvider
              stripe={stripePromise}
              options={{ clientSecret: checkoutData?.clientSecret }}
            >
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          </Box>
        ) : (
          <>
            <ProjectDialogForm
              isInstantHabMap={product?._id === Res.ids.products.INSTANT_HAB_MAP}
              AOIInfo={AOIInfo}
              onProjectChange={(name, description) => {
                setProjectName(name);
                setProgressProjectName(name);
                setProjectDescription(description);
              }}
            />

            <Divider sx={{ marginY: '16px' }} />

            <ProjectDialogFooter
              disabled={!projectName}
              text={getTooltipText()}
              onClose={onClose}
              onContinue={handleSubmitProject}
              onBuyNow={handleOnBuyNow}
              isInstantHabMap={product?._id === Res.ids.products.INSTANT_HAB_MAP}
            />
          </>
        )}
      </DialogContent>

      <ConfirmationDialog
        title="Confirmation"
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        onAgree={() => handleSubmitProject()}
      >
        <Typography>
          You are about to submit a request for a quote for {product?.name}. Please confirm your request.
        </Typography>
      </ConfirmationDialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Backdrop
        open={isCreatingInstantHabMap}
        sx={(theme) => ({
          flexDirection: 'column',
          color: 'var(--gentian-white)',
          bgcolor: 'rgba(0, 0, 0, 0.6)',
          gap: '8px',
          zIndex: theme.zIndex.drawer + 1,
        })}
      >
        <CircularProgress color="inherit" />
        <Typography>Creating project...</Typography>
      </Backdrop>
    </>
  );
};
