import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Typography, LinearProgress, Link, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useProgress from '../../core/hooks/useProgress';
import { LoadingEllipsis } from '../LoadingEllipsis';

const ProgressSnackbar: React.FC = () => {
  const { data: progressData, isProgressPopupOpen, setIsProgressPopupOpen } = useProgress();
  const [hidden, setHidden] = useState(isProgressPopupOpen);
  useEffect(() => {
    if (progressData?.status !== 'completed') {
      setHidden(isProgressPopupOpen);
    }
  }, [isProgressPopupOpen, progressData]);

  // If no progress data is available, render nothing.
  if (!progressData || hidden) return null;

  // Helper function to clear the progress (hide the progress footer)
  const handleClose = () => {
    setHidden(true);
  };

  const progressContent = () => {
    // Finished state: if processing failed.
    if (progressData.status === 'failed') {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '8px 32px 8px 16px',
            backgroundColor: 'error.light',
            color: 'error.contrastText',

            minWidth: 350,
            position: 'relative',
          }}
        >
          <Typography variant="body2">
            Processing failed. Please contact{' '}
            <Link href="mailto:info@gentian.team" underline="always" color="inherit">
              info@gentian.team
            </Link>
            .
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: 'absolute', right: 4 }}
            size="small"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      );
    }

    // Case: Processing with an error message but not finished.
    if (progressData.status === 'processing' && progressData.errorMessage?.trim()) {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '8px 16px',
            backgroundColor: 'warning.light',
            color: 'warning.contrastText',
            minWidth: 350,
            cursor: 'pointer',
          }}
        >
          <Typography variant="body2">An unexpected error occurred. We're trying again.</Typography>
        </Box>
      );
    }

    // Case: Still processing normally.
    if (progressData.status !== 'completed') {
      const progressPercent = progressData.progress * 100;
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '8px 16px',
            backgroundColor: 'background.paper',
            minWidth: 350,
            cursor: 'pointer',
          }}
        >
          <Typography variant="body2" sx={{ marginRight: 1, minWidth: '100px', textTransform: 'capitalize' }}>
            {progressData?.status}
            <LoadingEllipsis isLoading={progressData?.status === 'processing'} />
          </Typography>
          <Box sx={{ flexGrow: 1, marginRight: 1 }}>
            <LinearProgress
              variant="determinate"
              value={progressPercent}
              sx={{ height: 8, borderRadius: 2 }}
            />
          </Box>
          <Typography variant="body2">{Math.round(progressPercent)}%</Typography>
        </Box>
      );
    }

    // Finished state: if processing completed successfully.
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '8px 32px',
          backgroundColor: 'background.paper',
          minWidth: 350,
          position: 'relative',
        }}
      >
        <Typography variant="body2">
          Processing complete.{' '}
          <Link
            component={RouterLink}
            to={progressData.resultUrl}
            onClick={handleClose}
            underline="hover"
            color="primary"
          >
            View result
          </Link>
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', right: 4 }}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
    );
  };

  return (
    <Box
      onClick={() => setIsProgressPopupOpen(true)}
      sx={{
        position: 'fixed',
        bottom: 16,
        right: 16,
        borderRadius: '8px',
        boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.5)',
        overflow: 'hidden',
      }}
    >
      {progressContent()}
    </Box>
  );
};

export default ProgressSnackbar;
