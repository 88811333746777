import Joyride, { EVENTS, STATUS, Step } from 'react-joyride';
import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import { Info } from '@mui/icons-material';

export interface MapOnboardingProps {
  userName?: string;
  onClose?: () => void;
}

export const MapWalkThroughComponent: React.FC<MapOnboardingProps> = ({ onClose, userName }) => {
  const [run, setRun] = React.useState(false);
  const [step, setStep] = React.useState(0);

  useEffect(() => {
    localStorage.getItem('request-survey-map-onboarding-done') === 'true' ? setRun(false) : setRun(true);
  }, [userName]);

  const steps: Step[] = [
    {
      content: (
        <Typography>
          Welcome! Let's walk through how to request an ecological survey in just ten easy steps.
        </Typography>
      ),
      locale: {
        skip: <strong aria-label="skip">No thanks, I've got this.</strong>,
        next: <strong aria-label="next">Great, show me!</strong>,
      },
      placement: 'center',
      target: 'body',
    },
    {
      content: <Typography>Start by using this search box to locate your site.</Typography>,
      placement: 'bottom-end',
      target: '#map-search-address-control',
    },
    {
      content: (
        <Typography>Next, use this tool to draw a polygon outlining your survey area on the map.</Typography>
      ),
      placement: 'right',
      target: '.mapbox-gl-draw_polygon',
    },
    {
      content: (
        <Typography sx={{ display: 'flex' }}>
          <Info /> To draw a polygon, click on the map to create a point, then click again to create a line.
          Continue clicking to create a shape. To finish, double-click or press the Enter key.
        </Typography>
      ),
      placement: 'center',
      target: 'body',
    },
    {
      content: (
        <Typography>
          Use this tool to edit the selected polygon. If no polygon is selected, it will enable you to draw a
          new one.
        </Typography>
      ),
      placement: 'right',
      target: '#map-edit-polygon-control',
    },
    {
      content: (
        <Typography>
          Alternatively, you can upload your survey area as a shapefile, kml/kmz or geojson file if you have
          one ready.
        </Typography>
      ),
      placement: 'top',
      target: '#map-load-aoi-component',
    },
    {
      content: <Typography>Use this tool to reset your work. Be cautious, all work will be lost.</Typography>,
      placement: 'right',
      target: '#map-reset-control',
    },
    {
      content: <Typography>Feel free to change the map style here to suit your preferences.</Typography>,
      placement: 'top-end',
      target: '#map-style-switch-control',
    },
    {
      content: (
        <Typography>
          Use the fullscreen button to expand your working area and get a better view of the map.
        </Typography>
      ),
      placement: 'left',
      target: '.mapboxgl-ctrl-fullscreen',
    },
    {
      content: (
        <Typography>
          Here you can see the size of your survey area. Click the button on the right to toggle between
          square kilometers, acres and hectares.
        </Typography>
      ),
      placement: 'left',
      target: '#map-area-calculation-control',
    },
    {
      content: (
        <Typography>
          Click here to download your survey area, so you can easily upload it again in the future.
        </Typography>
      ),
      placement: 'top',
      target: '#map-download-aoi-container',
    },
    {
      content: (
        <Typography>Once you're ready, click here to proceed and select the products you’d like.</Typography>
      ),
      placement: 'top',
      target: '#map-checkout-button',
    },
    {
      content: (
        <Typography>
          You're all set! You've successfully learned how to use the map to request ecological surveys.
        </Typography>
      ),
      placement: 'center',
      target: 'body',
    },
  ];

  const handleJoyrideCallback = (data) => {
    const { status, type, action } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
      localStorage.setItem('request-survey-map-onboarding-done', 'true');
      onClose?.();
    }

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      setStep((s) => s + 1);
    }
    if (action === 'prev') {
      setStep((s) => s - 1);
    }
  };

  return (
    <Joyride
      steps={steps}
      run={run}
      callback={handleJoyrideCallback}
      stepIndex={step}
      continuous
      scrollToFirstStep
      showProgress
      showSkipButton
      hideBackButton
      hideCloseButton
      disableCloseOnEsc
      disableOverlayClose
      locale={{ last: 'Done' }}
      styles={{
        buttonNext: {
          backgroundColor: 'var(--gentian-secondary)',
          borderColor: 'var(--gentian-secondary)',
          color: '#fff',
        },
        buttonBack: {
          color: 'var(--gentian-dark-gray)',
        },
        buttonSkip: {
          color: 'var(--gentian-dark-gray)',
        },
      }}
    />
  );
};
