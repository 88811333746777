import { Box } from '@mui/material';
import './overview.css';

export const FindOutMoreOverviewFragment = () => (
  <Box className="find-out-more-content" sx={{ width: '100%' }}>
    <table cellSpacing="0" style={{ width: '100%' }}>
      <tbody>
        <tr className="c14">
          <td className="row_label" colSpan={1} rowSpan={1} style={{ borderRight: 'solid 1px #fff' }}>
            <p className="c2 c3">
              <span className="c25"></span>
            </p>
          </td>
          <td className="col_label" colSpan={1} rowSpan={1}>
            <p className="c26">
              <span className="">URBAN PRODUCTS</span>
            </p>
          </td>
          <td className="col_label" colSpan={1} rowSpan={1}>
            <p className="c26">
              <span className="c10">HABITAT MAPPING</span>
            </p>
          </td>
        </tr>
        <tr className="">
          <td className="row_label" colSpan={1} rowSpan={1}>
            <p className="c2">
              <span className="c10">Description</span>
            </p>
          </td>
          <td className="c15" colSpan={1} rowSpan={1}>
            <p className="c2">
              <span className="c0">
                Our urban-focused solution provides a comprehensive assessment of urban greenery, biodiversity
                metrics and uplift opportunities via a report and digital maps.
              </span>
            </p>
          </td>
          <td className="c15" colSpan={1} rowSpan={1}>
            <p className="c2">
              <span className="c0">
                Our habitat mapping solution is perfect for landowners, real estate developers and
                corporations that need an assessment of biodiversity over larger areas, or in rural contexts.
              </span>
            </p>
          </td>
        </tr>
        {/* <tr className="">
          <td className="row_label" colSpan={1} rowSpan={1}>
            <p className="c2">
              <span className="c10">Deliverables</span>
            </p>
          </td>
          <td className="c16" colSpan={1} rowSpan={1}>
            <p className="c2 c3">
              <span className="c0"></span>
            </p>
            <ul className="c7 lst-kix_gd31bb3ohnmy-0 start">
              <li className="c1 li-bullet-0">
                <span className="c0">Map of green roofs by type (intensive, extensive)</span>
              </li>
              <li className="c1 li-bullet-0">
                <span className="c0">Green roof biodiversity assessment</span>
              </li>
              <li className="c1 li-bullet-0">
                <span className="c0">Map of buildings with retrofittable potential for green roofs*</span>
              </li>
              <li className="c1 li-bullet-0">
                <span className="c0">
                  Map of buildings with retrofittable potential for biosolar roofs, with forecast of renewable
                  energy generation
                </span>
              </li>
              <li className="c1 li-bullet-0">
                <span className="c0">Tree survey</span>
              </li>
              <li className="c1 li-bullet-0">
                <span className="c0">Parks and residential garden survey</span>
              </li>
              <li className="c1 li-bullet-0">
                <span className="c0">Habitat mapping and biodiversity metrics (BNG)**</span>
              </li>
              <li className="c1 li-bullet-0">
                <span className="c0">Assessment of opportunities for biodiversity / greening uplift</span>
              </li>
              <li className="c1 li-bullet-0">
                <span className="c0">Analysis of surrounding 2km (e.g. protected areas in the vicinity)</span>
              </li>
            </ul>
            <p className="c2 c3">
              <span className="c0"></span>
            </p>
            <p className="c2">
              <span className="c12">
                *Currently only available in UK and EU with other territories subject to prior consultation
              </span>
              <span className="c5">&nbsp;with Gentian</span>
            </p>
            <p className="c2">
              <span className="c12">
                ** Based on the UKHab, IUCN, or EUNIS habitat classNameification systems (as required by
                customer)
              </span>
            </p>
          </td>
          <td className="c16" colSpan={1} rowSpan={1}>
            <p className="c2 c3">
              <span className="c0"></span>
            </p>
            <ul className="c7 lst-kix_lk83r123g0gq-0 start">
              <li className="c1 li-bullet-0">
                <span className="c0">Map of habitat types*</span>
              </li>
              <li className="c1 li-bullet-0">
                <span className="c0">Map of habitat type distinctiveness</span>
              </li>
              <li className="c1 li-bullet-0">
                <span className="c0">Water features survey</span>
              </li>
              <li className="c1 li-bullet-0">
                <span className="c0">Analysis of hedges</span>
              </li>
              <li className="c1 li-bullet-0">
                <span className="c0">Identification of individual trees</span>
              </li>
              <li className="c1 li-bullet-0">
                <span className="c0">Biodiversity metrics (BNG)</span>
              </li>
              <li className="c1 li-bullet-0">
                <span className="c0">Analysis of surrounding 2km (e.g. protected areas in the vicinity)</span>
              </li>
            </ul>
            <p className="c2 c3">
              <span className="c0"></span>
            </p>
            <ul className="c7 lst-kix_gd31bb3ohnmy-0">
              <li className="c1 li-bullet-0">
                <span className="c21">[Optional] </span>
                <span className="c18">Assessment of opportunities for biodiversity / greening uplift</span>
              </li>
            </ul>
            <p className="c2 c3">
              <span className="c0"></span>
            </p>
            <p className="c2">
              <span className="c12">
                * Based on the UKHab, IUCN, or EUNIS habitat classNameification systems (as required by
                customer)
              </span>
            </p>
            <p className="c2 c3">
              <span className="c0"></span>
            </p>
            <p className="c2 c3">
              <span className="c0"></span>
            </p>
            <p className="c2 c3">
              <span className="c5"></span>
            </p>
          </td>
        </tr> */}
        <tr className="">
          <td className="row_label" colSpan={1} rowSpan={1}>
            <p className="c2">
              <span className="c10">Format </span>
            </p>
          </td>
          <td className="c16" colSpan={1} rowSpan={1}>
            <p className="c2">
              <span className="c0">- Report with summary and statistics</span>
            </p>
            <p className="c2">
              <span className="c0">- Digital maps (GIS files)</span>
            </p>
            <p className="c2">
              <span className="c0">- Online visualisation (access to Gentian online platform)</span>
            </p>
            <p className="c2 c3">
              <span className="c0"></span>
            </p>
          </td>
          <td className="c16" colSpan={1} rowSpan={1}>
            <p className="c2">
              <span className="c0">- Report with summary and statistics</span>
            </p>
            <p className="c2">
              <span className="c0">- Digital maps (GIS files)</span>
            </p>
            <p className="c2">
              <span className="c18">- Online visualisation (access to Gentian online platform)</span>
            </p>
          </td>
        </tr>
        <tr className="">
          <td className="row_label" colSpan={1} rowSpan={1}>
            <p className="c2">
              <span className="c10">Geographic Coverage</span>
            </p>
          </td>
          <td className="c16" colSpan={1} rowSpan={1}>
            <p className="c2">
              <span className="c0">Worldwide</span>
            </p>
            <p className="c2 c3">
              <span className="c0"></span>
            </p>
          </td>
          <td className="c16" colSpan={1} rowSpan={1}>
            <p className="c2">
              <span className="c0">Worldwide*</span>
            </p>
            <p className="c2 c3">
              <span className="c0"></span>
            </p>
            <p className="c2">
              <span className="c5">
                *Immediately available in Europe, other territories available but subject to prior
                consultation in order to estimate delivery time.
              </span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p className="c3 c11">
      <span className="c28"></span>
    </p>
    <p className="c11 c3">
      <span className="c17"></span>
    </p>
    <div>
      <p className="c9 c3">
        <span className="c8"></span>
      </p>
    </div>
  </Box>
);
