import './style.css';
import React, { useState } from 'react';
import { IProject } from '../../core/interfaces/project.interface';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  CardMedia,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { Edit, CheckCircle, OpenInNew } from '@mui/icons-material'; // Import CheckCircle
import { ProjectUpdateModal } from '../ProjectUpdateModal';
import { DownloadProjectComponent } from '../DownloadProject';
import { OpenProjectComponent } from '../OpenProject';
import useProgress from '../../core/hooks/useProgress';

export interface PendingProjectsCardsProps {
  projects: IProject[];
  onRowSelect?: (project: IProject) => void;
  onEditProject?: (project: IProject) => void;
}

export const PendingProjectsCardsComponent: React.FC<PendingProjectsCardsProps> = ({
  projects,
  onEditProject,
}) => {
  const { data } = useProgress();
  const [codeEditModalOpen, setCodeEditModalOpen] = useState(false);
  const [project, setProject] = useState<IProject | undefined>(undefined);

  return (
    <Box>
      {codeEditModalOpen && (
        <ProjectUpdateModal
          field="name"
          project={project}
          onClose={() => setCodeEditModalOpen(false)}
          onSaveClick={(value) => {
            if (project) {
              project.name = value;
              onEditProject?.(project);
              setCodeEditModalOpen(false);
            }
          }}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 2,
          justifyContent: 'flex-start',
        }}
      >
        {projects.map((project) => {
          return (
            <Card
              key={project._id}
              sx={{
                width: 'calc(33% - 8px)',
                maxWidth: '300px',
                marginBottom: 2,
                display: 'flex',
                flexDirection: 'column',
                padding: 1,
                borderRadius: '8px',
              }}
            >
              <CardHeader
                sx={{
                  padding: '8px !important',
                }}
                title={
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <Typography variant="h6">{project.name}</Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                      }}
                    >
                      {!project.hasResult ? (
                        <CircularProgress size={20} value={data?.progress * 100} />
                      ) : data?.progress === undefined || data?.progress === 1 ? (
                        <CheckCircle sx={{ color: 'green' }} />
                      ) : (
                        <CircularProgress size={20} value={data?.progress * 100} />
                      )}
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          setProject(project);
                          setCodeEditModalOpen(true);
                        }}
                      >
                        <Tooltip title="Edit project name">
                          <Edit />
                        </Tooltip>
                      </IconButton>
                    </Box>
                  </Box>
                }
              />
              <CardMedia
                image={project.AOIThumbnail}
                title={project.name}
                component="img"
                sx={{
                  height: 150,
                  padding: '8px',
                  display: 'block',
                  margin: 'auto',
                  width: '83%',
                }}
              />
              <CardActions sx={{ justifyContent: 'flex-end', marginTop: 'auto' }}>
                <Box>
                  <DownloadProjectComponent AOI={project.AOI} />
                </Box>
                <Box>
                  <OpenProjectComponent AOI={project.AOI} />
                </Box>
                {project.hasResult ? (
                  <Button
                    variant="contained"
                    onClick={() => (window.location.href = `/survey-result/${project._id}`)}
                  >
                    Result <OpenInNew />
                  </Button>
                ) : (
                  <Typography variant="body2" sx={{ color: 'text.secondary', padding: '8px' }}>
                    Pending
                  </Typography>
                )}
              </CardActions>
            </Card>
          );
        })}
      </Box>
    </Box>
  );
};
