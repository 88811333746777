import React from 'react';
import { Box, Typography, LinearProgress, IconButton, Dialog, useTheme, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useProgress from '../../core/hooks/useProgress';
import ProgressSnackbar from '../ProgressSnackbar';
import { LoadingEllipsis } from '../LoadingEllipsis';

export const ProgressPopup: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { data, projectName, isProgressPopupOpen, setIsProgressPopupOpen } = useProgress();

  const progress = (data?.progress || 0) * 100;

  return (
    <>
      <Dialog
        open={isProgressPopupOpen}
        onClose={() => setIsProgressPopupOpen(false)}
        disableScrollLock
        maxWidth="xl"
        PaperProps={{
          sx: {
            margin: isMobile ? 0 : '32px',
          },
        }}
      >
        <Box
          sx={{
            height: 350,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <IconButton
            aria-label="close"
            onClick={() => setIsProgressPopupOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="h2">
            Project Name: {projectName}
          </Typography>
          <Typography sx={{ mt: 2, textTransform: 'capitalize' }}>
            {data?.status}
            <LoadingEllipsis isLoading={data?.status === 'processing'} />
          </Typography>
          <Box
            sx={{
              position: 'relative',
              mt: 2,
              width: '100%',
              marginTop: 2,
            }}
          >
            <Typography sx={{ mt: 2 }}>{progress}%</Typography>
            <LinearProgress variant="determinate" value={progress} sx={{ height: 15, borderRadius: 5 }} />
          </Box>
          <Typography sx={{ mt: 2 }}>Like everything in nature, good things take time to mature.</Typography>
          <Typography>
            Results will continue to process even if you disconnect from the platform and can be found under
            'My Projects'.
          </Typography>
        </Box>
      </Dialog>

      <ProgressSnackbar />
    </>
  );
};
