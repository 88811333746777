import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { router } from './router';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, CircularProgress, ThemeProvider, CssBaseline } from '@mui/material';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useJsApiLoader } from '@react-google-maps/api';
import { Res } from './resources';
import { datadogLogs } from '@datadog/browser-logs';
import { ProgressProvider } from './core/contexts/progress.context';
import { theme } from './Styles/theme';

const queryClient = new QueryClient();

function App() {
  const { getAccessTokenSilently, isAuthenticated, isLoading: isAth0Loading } = useAuth0();
  const { isLoaded: isGoogleLoaded } = useJsApiLoader({
    googleMapsApiKey: Res.components.maps.GOOGLE_TOKEN,
    libraries: ['places'],
  });

  const [isInitializing, setIsInitializing] = React.useState(true);

  useEffect(() => {
    datadogLogs.logger.info(`Checking if user is authenticated: ${isAuthenticated}`, { isAuthenticated });

    const storeAccessToken = async () => {
      datadogLogs.logger.info('Retrieving auth0 access token...');
      const newToken = await getAccessTokenSilently();
      if (newToken) {
        datadogLogs.logger.info('Storing access token...');
        localStorage.setItem('gop-auth-token', newToken);
      }
      setIsInitializing(false);
    };

    if (isAuthenticated) {
      storeAccessToken();
    } else {
      setIsInitializing(true);
    }
  }, [isAuthenticated]);

  return isAth0Loading || !isGoogleLoaded ? (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ProgressProvider>{!isInitializing && <RouterProvider router={router} />}</ProgressProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
