import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import './style.css';

import mapboxgl, {
  FullscreenControl,
  GeoJSONSource,
  GeolocateControl,
  Map,
  NavigationControl,
} from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import * as turf from '@turf/turf';

import React, { useEffect, useRef } from 'react';
import { SxProps, styled, useMediaQuery, useTheme } from '@mui/material';
import { AreaCalculationControl } from './controls/AreaCalculationControl';
import { MAP_STYLES, MapStyleSwitchControl } from './controls/MapStyleSwitchControl';
import { MapWalkThroughComponent } from './WalkThrough';
import { PointEditControl } from './controls/EditPointControl';
import { ResetControl } from './controls/ResetControl';
import { GoogleSearchControl } from './controls/GoogleSearchControl';
import CustomPolygonMode from './controls/CustomPolygonMode';
import StaticMode from '@mapbox/mapbox-gl-draw-static-mode';
import { datadogLogs } from '@datadog/browser-logs';
import { ScenarioPlanningBarControl } from './controls/ScenarioPlanningBarControl';
import { ClassifyFeatureModal } from '../ClassifyFeature';
import { CommentControl } from './CommentControl';
import { ICoordinateComments } from '../../core/interfaces/coordinate-comments.interface';

const MAP_CONTAINER_ID = 'map_container';

const MapContainer = styled('div')({});

export interface MapProps {
  accessToken: string;
  onShapeDrawn?: (shape: GeoJSON.FeatureCollection) => void;
  onLoad?: (map: Map) => void;
  onMouseEnter?: (map: Map, e: any) => void;
  onUpdateCoordinateComment?: (coordinateComments: ICoordinateComments) => void;
  coordinateComment?: ICoordinateComments[];
  onDeleteCoordinateComment?: (coordinateComments: ICoordinateComments) => void;
  initialShape?: GeoJSON.FeatureCollection;
  filteredLayers?: { id: string; visible: boolean }[];
  readOnly?: boolean;
  resultsOnly?: boolean; // New prop for resultsOnly mode
  sx?: SxProps;
  hideMapStyleSwitchControl?: boolean;
  sources?: any;
  layers?: any;
  patterns?: any;
  style?: MAP_STYLES;
  drawableArea?: GeoJSON.Feature;
  habitatOptions?: string[];
}

export const MapComponent: React.FC<MapProps> = ({
  accessToken,
  initialShape,
  readOnly,
  resultsOnly, // Destructure the new prop
  onShapeDrawn,
  onLoad,
  onUpdateCoordinateComment,
  onDeleteCoordinateComment,
  onMouseEnter,
  coordinateComment,
  filteredLayers,
  sx,
  hideMapStyleSwitchControl,
  sources,
  layers,
  patterns,
  style,
  drawableArea,
  habitatOptions,
}) => {
  mapboxgl.accessToken = accessToken;

  const map = useRef<Map | null>(null);
  const mapContainer = useRef(null);
  const drawControl = useRef<MapboxDraw | null>(null);
  const areaCalculationControl = useRef<AreaCalculationControl | null>(null);
  const scenarioPlanningBarControl = useRef<ScenarioPlanningBarControl | null>(null);
  const commentControl = useRef<CommentControl | null>(null);

  const currentFeature = React.useRef<GeoJSON.FeatureCollection>(turf.featureCollection([]));
  const [history, setHistory] = React.useState<GeoJSON.FeatureCollection[]>([]);
  const [redoHistory, setRedoHistory] = React.useState<GeoJSON.FeatureCollection[]>([]);
  const [loadDone, setLoadDone] = React.useState<boolean>(false);
  const [showClassifyModal, setShowClassifyModal] = React.useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const updateHistory = (featureCollection: GeoJSON.FeatureCollection) => {
    history.push(currentFeature.current);
    if (history.length > 1000) {
      history.shift();
    }
    setHistory(history);
    redoHistory.splice(0, redoHistory.length);
    setRedoHistory(redoHistory);
    currentFeature.current = featureCollection;
  };

  const updateShape = () => {
    const shapeInfo = drawControl.current?.getAll();
    areaCalculationControl.current.update(shapeInfo);

    onShapeDrawn?.(shapeInfo);
  };

  const keydownListener = (e: KeyboardEvent) => {
    if (e.key === 'z' && e.ctrlKey && !e.shiftKey) {
      if (history.length) {
        const feature = history.pop();
        setHistory(history);
        redoHistory.push(currentFeature.current);
        setRedoHistory(redoHistory);
        currentFeature.current = feature;
        drawControl.current?.deleteAll();
        setTimeout(() => {
          drawControl.current?.set(currentFeature.current);
          updateShape();
        }, 0);
      }
    }

    if (e.key?.toLowerCase() === 'z' && e.ctrlKey && e.shiftKey) {
      if (redoHistory.length) {
        const feature = redoHistory.pop();
        setRedoHistory(redoHistory);
        history.push(currentFeature.current);
        setHistory(history);
        currentFeature.current = feature;
        drawControl.current?.deleteAll();
        setTimeout(() => {
          drawControl.current?.set(currentFeature.current);
          updateShape();
        }, 0);
      }
    }
  };

  useEffect(() => {
    if (!map.current) {
      const navigationControl = new NavigationControl({ showCompass: false });
      const searchControl = new GoogleSearchControl();
      const mapStyleSwitchControl = new MapStyleSwitchControl();
      const fullScreeControl = new FullscreenControl({ container: document.querySelector('body') });
      const geolocateControl = new GeolocateControl({
        showAccuracyCircle: false,
        trackUserLocation: false,
      });

      areaCalculationControl.current = new AreaCalculationControl();
      drawControl.current = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: !readOnly && !resultsOnly, // Disable polygon drawing in resultsOnly mode
          combine_features: false,
          uncombine_features: false,
          trash: !readOnly && !resultsOnly, // Disable trash in resultsOnly mode
        },
        styles: [
          // Polygon fill styles
          {
            id: 'gl-draw-polygon-fill-inactive',
            type: 'fill',
            filter: ['all', ['==', 'active', 'false'], ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
            paint: {
              'fill-color': '#3bb2d0',
              'fill-outline-color': '#3bb2d0',
              'fill-opacity': 0.1,
            },
          },
          {
            id: 'gl-draw-polygon-fill-active',
            type: 'fill',
            filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
            paint: {
              'fill-color': '#fbb03b',
              'fill-outline-color': '#fbb03b',
              'fill-opacity': 0.1,
            },
          },
          // Polygon stroke styles
          {
            id: 'gl-draw-polygon-stroke-inactive',
            type: 'line',
            filter: ['all', ['==', 'active', 'false'], ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
            layout: {
              'line-cap': 'round',
              'line-join': 'round',
            },
            paint: {
              'line-color': '#3bb2d0',
              'line-width': 2,
            },
          },
          {
            id: 'gl-draw-polygon-stroke-active',
            type: 'line',
            filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
            layout: {
              'line-cap': 'round',
              'line-join': 'round',
            },
            paint: {
              'line-color': '#fbb03b',
              'line-dasharray': ['literal', [0.5, 1.5]],
              'line-width': 2,
            },
          },
          // Vertex styles
          {
            id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
            type: 'circle',
            filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
            paint: {
              'circle-radius': 6,
              'circle-color': '#fff',
            },
          },
          {
            id: 'gl-draw-polygon-and-line-vertex-inactive',
            type: 'circle',
            filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
            paint: {
              'circle-radius': 4,
              'circle-color': '#fbb03b',
            },
          },
          // Midpoint styles
          {
            id: 'gl-draw-polygon-midpoint',
            type: 'circle',
            filter: ['all', ['==', 'meta', 'midpoint'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
            paint: {
              'circle-radius': 3,
              'circle-color': '#fbb03b',
            },
          },
          // Static styles
          {
            id: 'gl-draw-polygon-fill-static',
            type: 'fill',
            filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
            paint: {
              'fill-color': '#404040',
              'fill-outline-color': '#404040',
              'fill-opacity': 0.1,
            },
          },
          {
            id: 'gl-draw-polygon-stroke-static',
            type: 'line',
            filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
            layout: {
              'line-cap': 'round',
              'line-join': 'round',
            },
            paint: {
              'line-color': '#404040',
              'line-width': 2,
            },
          },
          // Line styles
          {
            id: 'gl-draw-line-inactive',
            type: 'line',
            filter: [
              'all',
              ['==', 'active', 'false'],
              ['==', '$type', 'LineString'],
              ['!=', 'mode', 'static'],
            ],
            layout: {
              'line-cap': 'round',
              'line-join': 'round',
            },
            paint: {
              'line-color': '#3bb2d0',
              'line-width': 2,
            },
          },
          {
            id: 'gl-draw-line-active',
            type: 'line',
            filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'LineString']],
            layout: {
              'line-cap': 'round',
              'line-join': 'round',
            },
            paint: {
              'line-color': '#fbb03b',
              'line-dasharray': ['literal', [0.5, 1.5]],
              'line-width': 2,
            },
          },
          {
            id: 'gl-draw-line-static',
            type: 'line',
            filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'LineString']],
            layout: {
              'line-cap': 'round',
              'line-join': 'round',
            },
            paint: {
              'line-color': '#404040',
              'line-width': 2,
            },
          },
          // Point styles
          {
            id: 'gl-draw-point-point-stroke-inactive',
            type: 'circle',
            filter: [
              'all',
              ['==', 'active', 'false'],
              ['==', '$type', 'Point'],
              ['==', 'meta', 'feature'],
              ['!=', 'mode', 'static'],
            ],
            paint: {
              'circle-radius': 6,
              'circle-color': '#fff',
            },
          },
          {
            id: 'gl-draw-point-inactive',
            type: 'circle',
            filter: [
              'all',
              ['==', 'active', 'false'],
              ['==', '$type', 'Point'],
              ['==', 'meta', 'feature'],
              ['!=', 'mode', 'static'],
            ],
            paint: {
              'circle-radius': 4,
              'circle-color': '#3bb2d0',
            },
          },
          {
            id: 'gl-draw-point-stroke-active',
            type: 'circle',
            filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Point'], ['==', 'meta', 'feature']],
            paint: {
              'circle-radius': 6,
              'circle-color': '#fff',
            },
          },
          {
            id: 'gl-draw-point-active',
            type: 'circle',
            filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Point'], ['==', 'meta', 'feature']],
            paint: {
              'circle-radius': 4,
              'circle-color': '#fbb03b',
            },
          },
          {
            id: 'gl-draw-point-static',
            type: 'circle',
            filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Point']],
            paint: {
              'circle-radius': 4,
              'circle-color': '#404040',
            },
          },
        ],
        modes: Object.assign(
          {
            custom_polygon: CustomPolygonMode,
            static: StaticMode,
          },
          MapboxDraw.modes,
        ) as any,
      });

      const editPointControl = new PointEditControl(drawControl.current);
      const resetControl = new ResetControl(drawControl.current, areaCalculationControl.current);

      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: !!style ? style : readOnly || resultsOnly ? MAP_STYLES.STREETS : MAP_STYLES.SATELLITE_STREETS,
        center: [0.1276, 51.5072],
        dragRotate: false,
      }).addControl(searchControl, 'top-left');

      if (!isMobile) {
        map.current.addControl(navigationControl, 'top-left');
        map.current.addControl(fullScreeControl, 'top-right');
      }

      if (!hideMapStyleSwitchControl) {
        map.current.addControl(mapStyleSwitchControl, 'bottom-left');
      }

      if (!readOnly && !resultsOnly) {
        map.current
          .addControl(resetControl, 'bottom-left')
          // .addControl(commentControl, 'bottom-left')
          .addControl(areaCalculationControl.current, isMobile ? 'top-left' : 'bottom-right')
          .addControl(new mapboxgl.ScaleControl(), 'bottom-right')
          .addControl(geolocateControl, isMobile ? 'top-left' : 'top-right');
      }

      map.current.addControl(drawControl.current, 'bottom-left');

      if (!readOnly && !resultsOnly) {
        map.current.addControl(editPointControl, 'bottom-left');
      }

      map.current.on('load', () => {
        if (!initialShape) {
          geolocateControl.trigger();
        }
        if (readOnly || resultsOnly) {
          drawControl.current.changeMode('static');
        }

        onLoad?.(map.current as Map);
        setLoadDone(true);
      });

      map.current.on('draw.create', (evt) => {
        updateHistory(turf.featureCollection(evt.features));
        updateShape();
      });
      map.current.on('draw.delete', (evt) => {
        updateHistory(turf.featureCollection(evt.features));
        updateShape();
      });
      map.current.on('draw.update', (evt) => {
        updateHistory(turf.featureCollection(evt.features));
        updateShape();
      });

      document.addEventListener('keydown', keydownListener);
    }
  }, [accessToken, onShapeDrawn]);

  useEffect(() => {
    if (initialShape?.features?.length) {
      map.current.stop();
      setTimeout(() => {
        if (readOnly || resultsOnly) {
          drawControl.current.set(initialShape);
        } else {
          drawControl.current.add(initialShape);
        }
        const shapeInfo = drawControl.current?.getAll();
        currentFeature.current = shapeInfo;
        areaCalculationControl.current.update(shapeInfo);
        const bbox = turf.bbox(shapeInfo);
        map.current.fitBounds(bbox as any, { padding: 50, maxZoom: 17 });
      }, 300);
    } else {
      drawControl.current?.deleteAll();
    }
  }, [initialShape]);

  useEffect(() => {
    if (map.current && loadDone && filteredLayers) {
      filteredLayers.forEach((layer) => {
        map.current.setLayoutProperty(layer.id, 'visibility', layer.visible ? 'visible' : 'none');
      });
    }
  }, [filteredLayers]);

  useEffect(() => {
    if (!loadDone) return;

    if (!sources?.length) return;

    sources?.forEach((source) => {
      const foundSource = map.current.getSource(source.id);
      if (!foundSource) {
        map.current.addSource(source.id, source.data);
        map.current.on('mouseenter', source.id, (e) => {
          onMouseEnter?.(map.current, e as any);
        });
      } else {
        if (foundSource.type === 'geojson') {
          (foundSource as GeoJSONSource).setData(source.data.data);
        }
      }
    });
  }, [loadDone, sources]);

  useEffect(() => {
    if (!loadDone) return;
    if (!sources?.length || !layers?.length) return;

    if (!map.current.getSource(sources[0].id)) return;

    layers?.forEach((layer) => {
      if (map.current.getLayer(layer.id)) {
        map.current.removeLayer(layer.id);
      }
      map.current.addLayer(layer);
    });
  }, [layers]);

  useEffect(() => {
    if (!loadDone) return;
    if (!patterns) return;

    Object.entries(patterns).forEach(([id, pattern]) => {
      map.current.loadImage(pattern as string, (error, image) => {
        if (error) {
          datadogLogs.logger.error('Error loading image', {
            error,
            name: 'loadImage',
            id,
            pattern,
          });
          throw error;
        }
        if (map.current.hasImage(id)) {
          map.current.removeImage(id);
        }
        map.current.addImage(id, image);
      });
    });
  }, [patterns]);

  // useEffect(() => {
  //   if (!map.current || !isPlanning || !sources?.length) return;

  //   if (!scenarioPlanningBarControl.current) {
  //     scenarioPlanningBarControl.current = new ScenarioPlanningBarControl({
  //       draw: drawControl.current,
  //       onClassifyClick: () => {
  //         console.log('setModalTrue');
  //         setShowClassifyModal(true);
  //       },
  //       drawArea,
  //     });
  //   }

  //   if (!map.current.hasControl(scenarioPlanningBarControl.current)) {
  //     map.current.addControl(scenarioPlanningBarControl.current, 'top-right');
  //   }
  // }, [sources, isPlanning]);

  useEffect(() => {
    // setDrawArea(drawableArea);
    if (scenarioPlanningBarControl.current) {
      scenarioPlanningBarControl.current.updateDrawArea(drawableArea);
    }
  }, [drawableArea]);

  useEffect(() => {
    if (!loadDone) return;
    if (!commentControl.current) return;
    if (coordinateComment) {
      commentControl.current.update(coordinateComment);
    }

    // controll add options to the scenario planning bar
  }, [coordinateComment, loadDone, commentControl.current]);
  useEffect(() => {
    if (!loadDone) return;
    if (!habitatOptions) return;

    if (!commentControl.current) {
      commentControl.current = new CommentControl(
        habitatOptions,
        onUpdateCoordinateComment,
        onDeleteCoordinateComment,
        coordinateComment,
      );
    }

    if (map.current.hasControl(commentControl.current)) {
      map.current.removeControl(commentControl.current);
    }

    if (!readOnly || resultsOnly) {
      map.current.addControl(commentControl.current, 'bottom-left');
    }
  }, [loadDone, habitatOptions]);

  return (
    <>
      <MapContainer ref={mapContainer} id={MAP_CONTAINER_ID} sx={{ ...sx }}></MapContainer>
      <MapWalkThroughComponent />
      <ClassifyFeatureModal
        featureClassification={{
          description: 'g1c - Modified Grassland',
          distinctiveness: 'Moderate',
          condition: 'Poor',
        }}
        onClose={() => setShowClassifyModal(false)}
        open={showClassifyModal}
      />
    </>
  );
};
