// src/Pages/Pricing/tokenInfo.tsx
import React, { useState } from 'react';
import { Container, Typography, Button, Box, TextField } from '@mui/material';
import GentainTokenComponent from '../../Components/GentainToken';
import { ContentTileComponent } from '../../Components/ContentTitle';

const PricingTokenInfo = () => {
  const [tokenCount, setTokenCount] = useState<number>(0);

  const handleTokenChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10);
    setTokenCount(isNaN(value) ? 0 : value);
  };

  const handleButtonClick = (value: number) => {
    setTokenCount(value);
  };

  const calculateCost = (tokens: number): number => {
    if (tokens === 1000) {
      return 950; // 5% bonus for 1000 tokens
    }
    return tokens;
  };

  const cost = calculateCost(tokenCount);

  return (
    <Box>
      <ContentTileComponent className="hide-on-fullscreen">
        <Box sx={{ pl: 'var(--content-spacing-narrow)' }}>Pricing</Box>
      </ContentTileComponent>
      <Container maxWidth="md">
        <Typography variant="h4" gutterBottom fontWeight="bold">
          Overview
        </Typography>
        <Typography variant="body1" gutterBottom>
          GentianTokens are used to buy geospatial data, task new imagery, or run analytics.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Minimum Initial Purchase: £100 (100 tokens).
        </Typography>
        <Typography variant="body1" gutterBottom>
          Top-Up Amount: Minimum of £10 (10 tokens) top up for additional tokens after the first order. Bulk
          Purchase Bonus: Buy 1,000 or more tokens and receive a 5% bonus! Tokens expire 24 months after
          purchase. Tokens are non-refundable and cannot be exchanged.
        </Typography>
        <Typography variant="h4" gutterBottom fontWeight="bold" paddingBottom={4}>
          How Many Tokens Would You Like to Buy?
        </Typography>

        <Box display="flex" justifyContent="center" mb={2} paddingBottom={4}>
          <Box display="flex" alignItems="center">
            <GentainTokenComponent height="42px" width="42px" />
            <TextField
              label="Number of Tokens"
              variant="outlined"
              type="number"
              value={tokenCount}
              onChange={handleTokenChange}
              sx={{ width: '150px', ml: 2 }}
            />
            <Typography variant="body1" sx={{ ml: 2 }}>
              Gentian Token(s)
            </Typography>
          </Box>
        </Box>

        <Box display="flex" justifyContent="center" mb={2}>
          <Box display="flex" gap={2}>
            <Button variant="outlined" color="primary" size="large" onClick={() => handleButtonClick(100)}>
              100
            </Button>
            <Button variant="outlined" color="primary" size="large" onClick={() => handleButtonClick(250)}>
              250
            </Button>
            <Button variant="outlined" color="primary" size="large" onClick={() => handleButtonClick(500)}>
              500
            </Button>
            <Box position="relative" display="inline-block">
              <Button variant="outlined" color="primary" size="large" onClick={() => handleButtonClick(1000)}>
                1000
              </Button>
              <Typography
                variant="caption"
                color="error"
                sx={{ position: 'absolute', top: -5, right: -5, mr: 1, mt: 0.5 }}
              >
                +5%
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
          <Typography variant="h5" component="div">
            =
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
          <Typography variant="h5" component="div">
            £{cost}
          </Typography>
        </Box>

        <Box display="flex" justifyContent="center">
          <Button variant="contained" color="primary" size="large">
            Top Up
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default PricingTokenInfo;
