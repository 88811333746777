import { createBrowserRouter, Outlet } from 'react-router-dom';
import { SurveyRequestPage } from './Pages/SurveyRequest';
import { FindOutMorePage } from './Pages/FindOutMore';
import ErrorPage from './Pages/Error';
import { PrivacyPolicyPage } from './Pages/PrivacyPolicy';
import { TermsAndConditionsPage } from './Pages/TermsAndConditions';
import { DashboardPage } from './Pages/Dashboard';
import { SurveyResultPage } from './Pages/SurveyResults';
import { ResultUploadPage } from './Pages/ResultsUpload';
import { VerifyEmailPage } from './Pages/VerifyEmail';
import { ProductsPage } from './Pages/Products';
import { Res } from './resources';
import { ProjectsPage } from './Pages/Projects';
import PricingPage from './Pages/Pricing';
import PricingTokenInfo from './Pages/Pricing/tokenInfo';
import MyDashboard from './Pages/MyDashboard';
import { HeaderComponent } from './Components/Header';
import { FooterComponent } from './Components/Footer';
import { ProgressPopup } from './Components/ProgressPopup';
import { Box } from '@mui/material';

export const router = createBrowserRouter([
  {
    element: (
      <Box display="flex" flexDirection="column" height="100%">
        <HeaderComponent />
        <Box flex={1}>
          <Outlet />
        </Box>
        <FooterComponent />
        <ProgressPopup />
      </Box>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: Res.feature_flags.ENABLE_DASHBOARD ? <DashboardPage /> : <SurveyRequestPage />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/survey-request',
        element: <SurveyRequestPage />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/result-upload',
        element: Res.feature_flags.ENABLE_RESULT_UPLOAD ? <ResultUploadPage /> : <ErrorPage />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/survey-result/:projectId',
        element: Res.feature_flags.ENABLE_VIEW_RESULTS ? <SurveyResultPage /> : <ErrorPage />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/dashboard',
        element: Res.feature_flags.ENABLE_DASHBOARD ? <DashboardPage /> : <ErrorPage />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/mydashboard',
        element: Res.feature_flags.ENABLE_MY_DASHBOARD ? <MyDashboard /> : <ErrorPage />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/projects',
        element: Res.feature_flags.ENABLE_PROJECTS ? <ProjectsPage /> : <ErrorPage />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/find-out-more/:productName',
        element: <FindOutMorePage />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/error',
        element: <ErrorPage />,
      },
      {
        path: '/privacy-policy',
        element: <PrivacyPolicyPage />,
      },
      {
        path: '/terms-and-conditions',
        element: <TermsAndConditionsPage />,
      },
      {
        path: '/verify-email',
        element: <VerifyEmailPage />,
      },
      {
        path: '/products',
        element: Res.feature_flags.ENABLE_PRODUCT_MANAGEMENT ? <ProductsPage /> : <ErrorPage />,
      },
      {
        path: '/pricing',
        element: Res.feature_flags.ENABLE_PRICING_INFO ? <PricingPage /> : <ErrorPage />,
      },
      {
        path: '/pricing/token_info',
        element: Res.feature_flags.ENABLE_PRICING_INFO ? <PricingTokenInfo /> : <ErrorPage />,
      },
      {
        path: '*',
        element: <ErrorPage />,
      },
    ],
  },
]);
