import React from 'react';
import { Box, Button, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ExternalLink } from '../ExternalLink';
import useProgress from '../../core/hooks/useProgress';

export interface ProductCardProps {
  title: string;
  description: string;
  img: string;
  children: React.ReactNode;
  disabled?: boolean;
  onButtonClick: () => void;
  link: string;
  isOnlyAvailableInUK?: boolean;
  isRequest?: boolean;
}

export const ProductCard: React.FC<ProductCardProps> = ({
  img,
  title,
  description,
  children,
  disabled = false,
  onButtonClick,
  link,
  isOnlyAvailableInUK = false,
  isRequest = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { data: progressData } = useProgress();

  return (
    <Box
      overflow="hidden"
      boxSizing="border-box"
      display="flex"
      flex={1}
      height="100%"
      border="1px solid var(--gentian-secondary)"
      borderRadius={2}
      sx={{
        transition: 'box-shadow 0.3s ease',
        ':hover': {
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        padding="16px"
        sx={{
          overflowY: isMobile ? 'unset' : 'auto',
        }}
      >
        <Box display="flex" gap={2} marginBottom={2}>
          <img
            src={img}
            alt={title}
            style={{
              width: '100px',
              height: '100px',
              objectFit: 'cover',
              borderRadius: 10,
            }}
          />
          <Box>
            <Typography fontSize="28px" fontWeight="bold">
              {title}
            </Typography>
            <Typography
              fontSize="12px"
              color="var(--gentian-gray)"
              whiteSpace="pre-wrap"
              marginBottom="4px"
              textAlign="left"
            >
              {description}
            </Typography>
            <Typography fontSize="12px" color="var(--gentian-gray)" whiteSpace="pre-wrap">
              {isOnlyAvailableInUK ? 'Only available in UK' : 'Available Globally'}
            </Typography>
          </Box>
        </Box>
        <Box flex="1" fontSize="18px" color="var(--gentian-gray)">
          {children}
        </Box>
        <Box
          position={isMobile ? 'unset' : 'sticky'}
          textAlign="center"
          width="100%"
          bottom="-16px"
          padding="16px 0"
          left={0}
          right={0}
          sx={{
            background: 'linear-gradient(to bottom, #ffffff00, white)',
          }}
        >
          <Tooltip
            title={
              !isRequest && progressData?.status === 'processing'
                ? 'You have a pending project still processing, please wait for it to complete.'
                : ''
            }
            placement="top"
          >
            <span>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={onButtonClick}
                disabled={disabled || (!isRequest && progressData?.status === 'processing')}
                sx={{
                  '&:disabled': {
                    color: 'var(--gentian-button-color-disabled)',
                    backgroundColor: 'var(--gentian-button-bg-disabled)',
                  },
                }}
              >
                {isRequest ? 'Request' : 'Buy Now'}
              </Button>
            </span>
          </Tooltip>
        </Box>
        <ExternalLink href={link}>Find Out More</ExternalLink>
      </Box>
    </Box>
  );
};
