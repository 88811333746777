import { createTheme } from '@mui/material/styles';

// declare module '@mui/material/styles' {
//   interface Palette {
//     tertiary: Palette['primary'];
//   }
//   interface PaletteOptions {
//     tertiary?: PaletteOptions['primary'];
//   }
// }

export const theme = createTheme({
  // TODO: adjust palette with new design colors
  // palette: {
  // primary: {
  //   main: 'var(--gentian-primary)',
  // },
  // secondary: {
  //   main: 'var(--gentian-secondary)',
  // },
  // tertiary: {
  //   main: 'var(--gentian-tertiary)',
  // },
  // background: {
  //   default: 'var(--gentian-light-gray)',
  // },
  // },
  breakpoints: {
    values: {
      xs: 0,
      sm: 687,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'var(--gentian-primary)',
          position: 'relative',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '8px',
        },
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          pointerEvents: 'none',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        'html, body, #root': {
          minHeight: '100%',
          height: '100%',
        },
        body: {
          margin: 0,
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'var(--gentian-light-gray)',
          fontFamily: "'lato', sans-serif",
        },
        code: {
          fontFamily: 'source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace',
        },
        '.font--italic': {
          fontStyle: 'italic',
        },
        '.font__hairline': {
          fontWeight: 100,
        },
        '.font__thin': {
          fontWeight: 200,
        },
        '.font__light': {
          fontWeight: 300,
        },
        '.font__regular': {
          fontWeight: 400,
        },
        '.font__medium': {
          fontWeight: 500,
        },
        '.font__semibold': {
          fontWeight: 600,
        },
        '.font__bold': {
          fontWeight: 700,
        },
        '.font__heavy': {
          fontWeight: 800,
        },
        '.font__black': {
          fontWeight: 900,
        },
        ':fullscreen .hide-on-fullscreen, :fullscreen ~ .hide-on-fullscreen, :fullscreen + .hide-on-fullscreen':
          {
            display: 'none',
          },
        '.color--primary': {
          color: 'var(--gentian-primary) !important',
        },
        '.color--secondary': {
          color: 'var(--gentian-secondary) !important',
        },
        '.color--tertiary': {
          color: 'var(--gentian-tertiary) !important',
        },
        '.bg--primary': {
          backgroundColor: 'var(--gentian-primary) !important',
        },
        '.bg--secondary': {
          backgroundColor: 'var(--gentian-secondary) !important',
        },
        '.bg--tertiary': {
          backgroundColor: 'var(--gentian-tertiary) !important',
        },
      },
    },
  },
});
