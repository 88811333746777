import { Box, Card, Divider, Typography } from '@mui/material';
import GreenRoofIdentificationSample from './assets/green-roof-identification.png';
import React from 'react';
import { FindOutMoreOverviewFragment } from './overview.fragment';

export const GreenRoofIdentificationFragment = () => {
  return (
    <Box
      className="find-out-more-content"
      sx={{
        display: 'flex',
        direction: 'row',
        m: 'var(--content-spacing)',
        pl: 'var(--content-spacing)',
        pr: 'var(--content-spacing)',
      }}
    >
      <Box sx={{ m: '0 var(--content-spacing) 0 0' }}>
        <Typography variant="h4" sx={{ p: 0, fontWeight: 'bold', color: '#545454' }}>
          Green roof identification
        </Typography>
        <Divider sx={{ m: 'var(--content-spacing-narrow) 0 var(--content-spacing-narrow) 0' }} />
        <Typography
          variant="body1"
          sx={{
            m: 'var(--content-spacing-narrow) 0 var(--content-spacing-narrow) 0',
          }}
        >
          <i>
            Green roofs provide important refuge for flora and fauna and can significantly increase climate
            resilience, stormwater retention and air quality across cities.
          </i>
        </Typography>
        <Typography sx={{ mt: 2 }}>
          <b>What is Green Roof Identification?</b>
        </Typography>
        <Typography
          variant="body1"
          sx={{ m: 'var(--content-spacing-narrow) 0 var(--content-spacing-narrow) 0' }}
        >
          Our Green Roof Identification solution detects and classifies all the green roofs in your area. Talk
          to us if you'd like to know more about how the green roofs in your area contribute to biodiversity,
          climate resilience or stormwater retention.
        </Typography>
        <Typography sx={{ mt: 2 }}>
          <b>What does Green Roof Identification include? </b>
        </Typography>
        <Typography
          variant="body1"
          sx={{ m: 'var(--content-spacing-narrow) 0 var(--content-spacing-narrow) 0' }}
        >
          Results are provided in the form of digital maps and a report.
        </Typography>
        <Typography sx={{ mb: 2, fontWeight: 'bold' }}>
          Download a{' '}
          <a href="/grid-and-grrf-case-study.pdf" download>
            case study
          </a>
        </Typography>
        <Card
          sx={{
            p: 'var(--content-spacing-narrow)',
            maxWidth: '800px',
            mb: 'var(--content-spacing)',
          }}
        >
          <img
            src={GreenRoofIdentificationSample}
            alt="Green Roof Identification Sample"
            style={{ width: '100%', maxWidth: '800px' }}
          />
        </Card>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 'bold',
            fontSize: '18px',
            color: '#545454',
            mt: 'var(--content-spacing)',
            mb: 'var(--content-spacing)',
          }}
        >
          Products Overview
        </Typography>
        <FindOutMoreOverviewFragment />
      </Box>
    </Box>
  );
};
