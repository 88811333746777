// src/Pages/Pricing/index.tsx
import React from 'react';
import {
  Container,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Grid,
  Box,
  List,
  ListItem,
  ListItemIcon,
  Link,
} from '@mui/material';
import GentainTokenComponent from '../../Components/GentainToken';
import CheckIcon from '@mui/icons-material/Check';
import { ContentTileComponent } from '../../Components/ContentTitle';

const PricingPage = () => {
  return (
    <Box>
      <ContentTileComponent className="hide-on-fullscreen">
        <Box sx={{ pl: 'var(--content-spacing-narrow)' }}>Pricing</Box>
      </ContentTileComponent>
      <Container maxWidth="md">
        <Typography variant="h4" gutterBottom fontWeight="bold">
          1. Pay-as-you-go
        </Typography>
        <Typography variant="body1" gutterBottom>
          Our default pricing is £30 per hectare, allowing you to pay for what you need as you map.
        </Typography>
        <Typography variant="h4" gutterBottom fontWeight="bold" sx={{ paddingTop: '20px' }}>
          2. Gentian Tokens
        </Typography>
        <Typography variant="body1" gutterBottom>
          You can enjoy a discounted rate of £25 per hectare by purchasing Gentian Tokens. The minimum initial
          order is £100, which provides you with 100 tokens. After your first purchase, you can easily top up
          with a minimum of £10 each time. Each Gentian Token is equivalent to £1.
        </Typography>
        <Typography variant="body1" gutterBottom>
          If you purchase 1,000 or more tokens at any time, we provide an additional 5% bonus. Tokens expire
          24 months after purchase. Tokens are non-refundable and cannot be exchanged.
        </Typography>
        <Grid container spacing={4} sx={{ marginTop: '20px' }}>
          <Grid item xs={12} sm={6}>
            <Card
              sx={{
                border: '2px solid',
                borderColor: 'var(--gentian-primary)',
                borderRadius: '8px',
                height: '100%',
                backgroundColor: 'transparent',
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <CardContent>
                <Typography variant="h5" component="div">
                  Pay-as-you-go
                </Typography>
                <List dense>
                  <ListItem>
                    <ListItemIcon>
                      <CheckIcon color="primary" />
                    </ListItemIcon>
                    <Typography variant="body2" color="text.secondary">
                      £30 per hectare
                    </Typography>
                  </ListItem>
                </List>
              </CardContent>
              <CardActions>
                <Box display="flex" justifyContent="center" width="100%">
                  <Button variant="contained" color="primary" size="large">
                    Start Mapping
                  </Button>
                </Box>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card
              sx={{
                border: '2px solid',
                borderColor: 'var(--gentian-primary)',
                borderRadius: '8px',
                height: '100%',
                backgroundColor: 'transparent',
                padding: '20px 10px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                marginBottom: '50px',
                gap: '10px',
              }}
            >
              <CardContent>
                <Box display="flex" alignItems="center">
                  <GentainTokenComponent height="40px" width="40px" />
                  <Typography variant="h5" component="div">
                    Gentian Tokens
                  </Typography>
                </Box>
                <Typography variant="body2" color="text.secondary">
                  *Minimum Initial Spend £100
                </Typography>
                <List dense>
                  <ListItem>
                    <ListItemIcon>
                      <CheckIcon color="primary" />
                    </ListItemIcon>
                    <GentainTokenComponent />
                    <Typography variant="body2" color="text.secondary">
                      25 Gentian tokens per hectare
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckIcon color="primary" />
                    </ListItemIcon>
                    <Typography variant="body2" color="text.secondary">
                      1 Gentian token(s) = £1
                    </Typography>
                  </ListItem>
                </List>
              </CardContent>
              <CardActions>
                <Link
                  href="/pricing/token_info"
                  underline="none"
                  color="inherit"
                  display="flex"
                  justifyContent="center"
                  width="100%"
                >
                  <Box display="flex" justifyContent="center" width="100%">
                    <Button variant="contained" color="primary" size="large">
                      TOP UP
                    </Button>
                  </Box>
                </Link>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default PricingPage;
