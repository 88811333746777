// TODO: Split it into multiple hooks
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IProject } from '../interfaces/project.interface';
import { User, useAuth0 } from '@auth0/auth0-react';
import { Res } from '../../resources';
import { IAccountData } from '../interfaces/account-data.interface';
import { IResult } from '../interfaces/result.interface';
import { IProduct } from '../interfaces/product.interface';
import { datadogLogs } from '@datadog/browser-logs';
import { ISharedResults } from '../interfaces/shared-results.interface';
import { ICoordinateComments } from '../interfaces/coordinate-comments.interface';
import { IBiodiversityUnit } from '../interfaces/BNG-units.interface';

const useGentian = () => {
  const { isAuthenticated } = useAuth0();
  const queryClient = useQueryClient();

  const retrieveToken = async (source: string) => {
    datadogLogs.logger.info(`requesting token for ${source}`, { name: source, isAuthenticated });
    if (!isAuthenticated) {
      datadogLogs.logger.info('User is not authenticated. Ignoring query', {
        source,
        isAuthenticated,
      });
      return null;
    }

    const token = localStorage.getItem('gop-auth-token');
    if (!!token) return token;

    datadogLogs.logger.info('No token found in the local storage. Canceling queries and mutations.', {
      name: source,
    });

    await queryClient.cancelQueries();
    await queryClient.cancelMutations();

    return null;
  };

  const createProject = useMutation(async (projectData: IProject) => {
    const token = await retrieveToken('createProject');
    if (!token) return;

    const response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/project`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(projectData),
    });

    if (!response.ok) {
      let text = '';
      let error = null as unknown as Error;
      try {
        error = await response.json();
        text = error.message;
      } finally {
        datadogLogs.logger.error(
          `${response.status}: ${response.statusText} - ${text}`,
          {
            name: 'createProject',
            error: error,
            projectData,
            baseURL: Res.api.gentian.BFF_BASE_URL,
            response: {
              status: response.status,
              statusText: response.statusText,
            },
          },
          error,
        );
        throw new Error(`${response.status}: ${response.statusText} - ${text}`);
      }
    }

    return response.json();
  });

  const updateProjectName = useMutation(async ({ projectId, name }: { projectId: string; name: string }) => {
    const token = await retrieveToken('updateProjectName');
    if (!token) return;

    const response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/project/${projectId}/name`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ name }),
    });

    if (!response.ok) {
      let text = '';
      let error = null as unknown as Error;
      try {
        error = await response.json();
        text = error.message;
      } finally {
        datadogLogs.logger.error(
          `${response.status}: ${response.statusText} - ${text}`,
          {
            name: 'updateProjectName',
            error: error,
            payload: { projectId, name },
            baseURL: Res.api.gentian.BFF_BASE_URL,
            response: {
              status: response.status,
              statusText: response.statusText,
            },
          },
          error,
        );
        throw new Error(`${response.status}: ${response.statusText} - ${text}`);
      }
    }

    return true;
  });

  const updateResult = useMutation(async (resultData: IResult) => {
    const token = await retrieveToken('updateResult');
    if (!token) return;

    if (!resultData.project?._id) {
      throw new Error('Project ID is required to update result');
    }

    const response = await fetch(
      `${Res.api.gentian.BFF_BASE_URL}/results/${resultData.project._id}/additional-notes`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ additionalNotes: resultData.additionalNotes }),
      },
    );

    if (!response.ok) {
      let text = '';
      let error = null as unknown as Error;
      try {
        error = await response.json();
        text = error.message;
      } finally {
        datadogLogs.logger.error(
          `${response.status}: ${response.statusText} - ${text}`,
          {
            name: 'updateResult',
            error: error,
            resultData,
            baseURL: Res.api.gentian.BFF_BASE_URL,
            response: {
              status: response.status,
              statusText: response.statusText,
            },
          },
          error,
        );
        throw new Error(`${response.status}: ${response.statusText} - ${text}`);
      }
    }

    return response;
  });

  const updateProjectCode = useMutation(async ({ projectId, code }: { projectId: string; code: string }) => {
    const token = await retrieveToken('updateProjectCode');
    if (!token) return;

    const response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/project/${projectId}/code`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ code }),
    });

    if (!response.ok) {
      let text = '';
      let error = null as unknown as Error;
      try {
        error = await response.json();
        text = error.message;
      } finally {
        datadogLogs.logger.error(
          `${response.status}: ${response.statusText} - ${text}`,
          {
            name: 'updateProjectCode',
            error: error,
            payload: { projectId, code },
            baseURL: Res.api.gentian.BFF_BASE_URL,
            response: {
              status: response.status,
              statusText: response.statusText,
            },
          },
          error,
        );
        throw new Error(`${response.status}: ${response.statusText} - ${text}`);
      }
    }

    return true;
  });

  const useCoordinateCommentsByResultId = (resultId: string, signal?: AbortSignal) => {
    return useQuery<unknown, unknown, ICoordinateComments[], any>(
      ['result_by_result_id', resultId],
      async () => {
        const token = await retrieveToken('getCoordinateCommentsByResultId');
        if (!token) return;

        const response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/results/${resultId}/coordinate-notes`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          signal,
          cache: 'no-cache',
        });

        if (!response?.ok) {
          return undefined;
        }

        const responseJson = await response.json();
        return (responseJson.resultData as ICoordinateComments[]) || [];
      },
      {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        cacheTime: 3600000,
        enabled: !!resultId && isAuthenticated,
      },
    );
  };

  const updateCoordinateComments = useMutation(
    async ({
      resultData,
      coordinateComments,
    }: {
      resultData: IResult;
      coordinateComments: ICoordinateComments;
    }) => {
      const token = await retrieveToken('updateCoordinateComments');
      if (!token) return;

      const response = await fetch(
        `${Res.api.gentian.BFF_BASE_URL}/results/${resultData._id}/coordinate-notes`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(coordinateComments),
        },
      );

      if (!response.ok) {
        let text = '';
        let error = null as unknown as Error;
        try {
          error = await response.json();
          text = error.message;
        } finally {
          datadogLogs.logger.error(
            `${response.status}: ${response.statusText} - ${text}`,
            {
              name: 'updateCoordinateComments',
              error: error,
              resultData,
              baseURL: Res.api.gentian.BFF_BASE_URL,
              response: {
                status: response.status,
                statusText: response.statusText,
              },
            },
            error,
          );
          throw new Error(`${response.status}: ${response.statusText} - ${text}`);
        }
      }

      return response;
    },
  );

  const createResult = useMutation(async (resultData: IResult) => {
    const token = await retrieveToken('createResult');
    if (!token) return;

    const response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/results`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(resultData),
    });

    if (!response.ok) {
      let text = '';
      let error = null as unknown as Error;
      try {
        error = await response.json();
        text = error.message;
      } finally {
        datadogLogs.logger.error(
          `${response.status}: ${response.statusText}`,
          {
            name: 'createResult',
            error: error,
            baseURL: Res.api.gentian.BFF_BASE_URL,
            response: {
              status: response.status,
              statusText: response.statusText,
            },
          },
          error,
        );
        throw new Error(`${response.status}: ${response.statusText} - ${text}`);
      }
    }

    return response.json();
  });

  const publishResult = useMutation(async (resultData: IResult) => {
    const token = await retrieveToken('publishResult');
    if (!token) return;

    const response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/results/publish`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(resultData),
    });

    if (!response.ok) {
      let text = '';
      let error = null as unknown as Error;
      try {
        error = await response.json();
        text = error.message;
      } finally {
        datadogLogs.logger.error(
          `${response.status}: ${response.statusText}`,
          {
            name: 'publishResult',
            error: error,
            baseURL: Res.api.gentian.BFF_BASE_URL,
            response: {
              status: response.status,
              statusText: response.statusText,
            },
          },
          error,
        );
        throw new Error(`${response.status}: ${response.statusText} - ${text}`);
      }
    }
  });

  const updateAccount = useMutation(async (userData: User) => {
    const token = await retrieveToken('updateAccount');
    if (!token) return;

    const response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/account/${userData.sub}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(userData),
    });

    if (!response.ok) {
      datadogLogs.logger.error('Failed to update user data', {
        name: 'updateAccount',
        response: {
          status: response.status,
          statusText: response.statusText,
        },
      });
      throw new Error('Failed to update user data');
    }

    return response.json();
  });

  const getResultByProjectIdFunction = async (projectId: string, signal?: AbortSignal) => {
    const token = await retrieveToken('getResultByProjectIdFunction');
    if (!token) return;

    const response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/results/project/${projectId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      signal,
      cache: 'no-cache',
    });

    if (!response?.ok) {
      return undefined;
    }

    const responseJson = await response.json();
    return responseJson?.resultData as IResult | undefined;
  };

  const useResultByProjectId = (projectId: string) => {
    return useQuery<unknown, unknown, IResult, any>(
      ['result_by_project_id', projectId],
      ({ signal }) => getResultByProjectIdFunction(projectId, signal),
      {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        cacheTime: 15 * 60 * 1000,
        enabled: !!projectId,
      },
    );
  };

  const useProjectById = (projectId: string) => {
    return useQuery<unknown, unknown, IProject, any>(
      ['project', projectId],
      async () => {
        const token = await retrieveToken('getProjectById');
        if (!token) return;

        const response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/project/${projectId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          datadogLogs.logger.error('Failed to fetch project data', {
            name: 'getProjectById',
            response: {
              status: response.status,
              statusText: response.statusText,
            },
          });
          throw new Error('Failed to fetch project data');
        }

        return response.json();
      },
      { refetchOnWindowFocus: false, refetchOnMount: false, cacheTime: 3600000 },
    );
  };

  const useAccount = () => {
    return useQuery<unknown, unknown, IAccountData, any>(['account'], async ({ signal }) => {
      const token = await retrieveToken('getAccount');
      if (!token) return;

      const response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/account/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        signal,
      });

      if (!response.ok) {
        datadogLogs.logger.error('Failed to fetch user data', {
          name: 'getAccount',
          response: {
            status: response.status,
            statusText: response.statusText,
          },
        });
        throw new Error('Failed to fetch user data');
      }

      return response.json();
    });
  };

  const useProjects = () => {
    return useQuery<unknown, unknown, IProject[], any>(
      ['projects'],
      async ({ signal }) => {
        const token = await retrieveToken('getProjects');
        if (!token) return;

        let response: Response;
        try {
          response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/project/`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            signal,
          });
        } catch (error) {
          if ((error as Error).name === 'AbortError') return;
          throw error;
        }

        if (!response.ok) {
          datadogLogs.logger.error('Failed to fetch user data', {
            name: 'getProjects',
            response: {
              status: response.status,
              statusText: response.statusText,
            },
          });
          throw new Error('Failed to fetch user data');
        }

        return response.json();
      },
      {
        retryDelay: 5000,
        enabled: isAuthenticated,
      },
    );
  };

  const useSharedResults = (enabled = true) => {
    return useQuery<unknown, unknown, ISharedResults[], any>(
      ['shared-results'],
      async ({ signal }) => {
        const token = await retrieveToken('getSharedResults');
        if (!token) return;

        let response: Response;
        try {
          response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/results/shared/`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            signal,
          });
        } catch (error) {
          if ((error as Error).name === 'AbortError') return;
          throw error;
        }

        if (!response?.ok) {
          datadogLogs.logger.error('Failed to fetch shared results', {
            name: 'getSharedResults',
            response: {
              status: response?.status,
              statusText: response?.statusText,
            },
          });
          throw new Error('Failed to fetch shared results');
        }

        return (await response?.json())?.resultData as ISharedResults[];
      },
      {
        cacheTime: 3600000,
        enabled: isAuthenticated && enabled,
        retry: 2,
        onError: (error) => console.warn(error),
        retryDelay: 5000,
      },
    );
  };

  const useProducts = () => {
    return useQuery<unknown, unknown, IProduct[], any>(
      ['all_products'],
      async ({ signal }) => {
        const token = await retrieveToken('getProducts');
        if (!token) return;

        const response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/products/active`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          signal,
        });

        if (!response.ok) {
          datadogLogs.logger.error('Failed to fetch products', {
            name: 'getProducts',
            response: {
              status: response.status,
              statusText: response.statusText,
            },
          });
          throw new Error('Failed to fetch products');
        }

        return response.json();
      },
      { refetchOnWindowFocus: false, refetchOnMount: false, cacheTime: 3600000, enabled: isAuthenticated },
    );
  };

  const useAllProducts = () => {
    return useQuery<unknown, unknown, IProduct[], any>(
      ['all_products'],
      async ({ signal }) => {
        const token = await retrieveToken('getAllProducts');
        if (!token) return;

        const response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/products/all`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          signal,
        });

        if (!response.ok) {
          datadogLogs.logger.error('Failed to fetch products', {
            name: 'getAllProducts',
            response: {
              status: response.status,
              statusText: response.statusText,
            },
          });
          throw new Error('Failed to fetch products');
        }

        return response.json();
      },
      { refetchOnWindowFocus: false, refetchOnMount: false, enabled: isAuthenticated },
    );
  };

  const useAllProjects = ({ status }: { status: 'has-result' | 'no-result' }) => {
    return useQuery<unknown, unknown, IProject[], string[]>(
      ['all_projects', status],
      async ({ signal }) => {
        const token = await retrieveToken('getAllProjects');
        if (!token) return;

        const response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/project/status/${status}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          signal,
        });

        if (!response.ok) {
          datadogLogs.logger.error('Failed to fetch user data', {
            name: 'getAllProjects',
            response: {
              status: response.status,
              statusText: response.statusText,
            },
          });
          throw new Error('Failed to fetch user data');
        }

        return response.json();
      },
      { refetchOnWindowFocus: false, refetchOnMount: false, enabled: isAuthenticated },
    );
  };

  const uploadFindOutMorePDF = useMutation(async ({ file, code }: { file: File; code: string }) => {
    const token = await retrieveToken('uploadFindOutMorePDF');
    if (!token) return;

    const formData = new FormData();
    formData.append('file', file);

    const response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/products/${code}/find-out-more`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    if (!response.ok) {
      datadogLogs.logger.error('Failed to upload file', {
        name: 'uploadFindOutMorePDF',
        response: {
          status: response.status,
          statusText: response.statusText,
        },
      });
      throw new Error('Failed to upload file');
    }

    return (await response.json()) as { publicUrl: string };
  });

  const createProduct = useMutation(async (productData: IProduct) => {
    const token = await retrieveToken('createProduct');
    if (!token) return;

    const response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/products`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(productData),
    });

    if (!response.ok) {
      datadogLogs.logger.error('Failed to create product', {
        name: 'createProduct',
        response: {
          status: response.status,
          statusText: response.statusText,
        },
      });
      throw new Error('Failed to create product');
    }

    return;
  });

  const updateProduct = useMutation(async (productData: IProduct) => {
    const token = await retrieveToken('updateProduct');
    if (!token) return;

    const response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/products/${productData._id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(productData),
    });

    if (!response.ok) {
      datadogLogs.logger.error('Failed to update product', {
        name: 'updateProduct',
        response: {
          status: response.status,
          statusText: response.statusText,
        },
      });
      throw new Error('Failed to update product');
    }

    return;
  });

  const addResult = useMutation(async (projectId: string) => {
    const token = await retrieveToken('addResult');
    if (!token) return;

    const response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/results/shared/${projectId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ projectId }),
    });

    if (!response.ok) {
      datadogLogs.logger.error('Failed to add shared result', {
        name: 'addResult',
        response: {
          status: response.status,
          statusText: response.statusText,
        },
      });
      throw new Error('Failed to create product');
    }

    return;
  });

  const createInstantHabmap = useMutation(async (projectData: IProject) => {
    const token = await retrieveToken('createInstantHabmap');
    if (!token) return;

    const response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/instant-hab-map`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(projectData),
    });

    if (!response.ok) {
      let text = '';
      let error = null as unknown as Error;
      try {
        error = await response.json();
        text = error.message;
      } finally {
        datadogLogs.logger.error(
          `${response.status}: ${response.statusText} - ${text}`,
          {
            name: 'createInstantHabmap',
            error: error,
            projectData,
            baseURL: Res.api.gentian.BFF_BASE_URL,
            response: {
              status: response.status,
              statusText: response.statusText,
            },
          },
          error,
        );
        throw new Error(`${response.status}: ${response.statusText} - ${text}`);
      }
    }

    return response.json();
  });

  const useUsage = ({
    start,
    limit,
    sort,
    sortDirection,
  }: {
    start: number;
    limit: number;
    sort: string;
    sortDirection: 'asc' | 'desc';
  }) => {
    return useQuery<unknown, unknown, any, any>(
      ['usage', start, limit, sort, sortDirection],
      async ({ signal }) => {
        const token = await retrieveToken('getUsage');
        if (!token) return;

        const response = await fetch(
          `${Res.api.gentian.BFF_BASE_URL}/usage?start=${start}&limit=${limit}&sort=${sort}&sortDirection=${sortDirection}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            signal,
          },
        );

        if (!response.ok) {
          datadogLogs.logger.error('Failed to fetch usage data', {
            name: 'getUsage',
          });
          throw new Error('Failed to fetch usage data');
        }
        return response.json();
      },
      {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        cacheTime: 3600000,
        enabled: isAuthenticated,
      },
    );
  };

  const setResultsPermissions = useMutation(
    async ({ projectId, shareResultsArtifacts }: { projectId: string; shareResultsArtifacts: boolean }) => {
      const token = await retrieveToken('setResultsPermissions');
      if (!token) return;

      const response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/results/permissions/${projectId}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ shareResultsArtifacts }),
      });

      if (!response.ok) {
        let text = '';
        let error = null as unknown as Error;
        try {
          error = await response.json();
          text = error.message;
        } finally {
          datadogLogs.logger.error(
            `${response.status}: ${response.statusText} - ${text}`,
            {
              name: 'setSharingPermissions',
              error: error,
              payload: { projectId, shareResultsArtifacts },
              baseURL: Res.api.gentian.BFF_BASE_URL,
              response: {
                status: response.status,
                statusText: response.statusText,
              },
            },
            error,
          );
          throw new Error(`${response.status}: ${response.statusText} - ${text}`);
        }
      }

      return true;
    },
  );

  const useBNGUnitsByProjectId = (projectId: string, signal?: AbortSignal) => {
    return useQuery<unknown, unknown, IBiodiversityUnit, any>(
      ['getBNGUnitsByProjectId', projectId],
      async () => {
        const token = await retrieveToken('getBNGUnitsByProjectId');
        if (!token) return;

        const response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/results/${projectId}/units`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          signal,
          cache: 'no-cache',
        });

        if (!response?.ok) {
          return undefined;
        }

        const responseJson = await response.json();
        return responseJson.resultData as IBiodiversityUnit;
      },
      {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        cacheTime: 3600000,
        enabled: !!projectId && isAuthenticated,
      },
    );
  };

  const createCheckoutSession = useMutation(
    async (projectData: IProject) => {
      const token = await retrieveToken('createCheckoutSession');
      if (!token) return;

      try {
        const response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/stripe/checkout-session`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(projectData),
        });

        if (!response.ok) {
          datadogLogs.logger.error('Failed to create checkout session', {
            name: 'createCheckoutSession',
            response: {
              status: response.status,
              statusText: response.statusText,
            },
          });
          throw new Error('Failed to create checkout session');
        }

        return response?.json() as unknown as { clientSecret: string; sessionId: string };
      } catch {
        return { clientSecret: undefined, sessionId: undefined };
      }
    },
    { mutationKey: ['checkout-session'] },
  );

  const usePaymentStatus = (sessionId: string) => {
    return useQuery(
      ['checkout-session', 'payment-status'],
      async ({ signal }) => {
        const token = await retrieveToken('getPaymentStatus');
        if (!token) return;

        try {
          const response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/stripe/session_status/${sessionId}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            signal,
          });

          if (!response.ok) {
            datadogLogs.logger.error('Failed to fetch payment status', {
              name: 'getPaymentStatus',
              response: {
                status: response.status,
                statusText: response.statusText,
              },
            });
            throw new Error('Failed to fetch payment status');
          }

          return response.json() as unknown as {
            status: 'complete' | 'open' | 'expired';
            paymentStatus: 'paid' | 'unpaid' | 'no_payment_required';
            clientSecret: string;
            clientEmail: string;
          };
        } catch {
          throw new Error('Failed to fetch payment status');
        }
      },
      {
        enabled: isAuthenticated && !!sessionId,
        refetchInterval: 2000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      },
    );
  };

  const deleteProject = useMutation(async (projectId: string) => {
    const token = await retrieveToken('deleteProject');
    if (!token) return;

    const response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/test-helper/project/${projectId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      datadogLogs.logger.error('Failed to delete project', {
        name: 'deleteProject',
        response: {
          status: response.status,
          statusText: response.statusText,
        },
      });
      throw new Error('Failed to delete project');
    }

    return;
  });

  const deleteCoordinateComment = useMutation(
    async ({ coordinateCommentsId }: { coordinateCommentsId: string }) => {
      const token = await retrieveToken('deleteCoordinateComment');
      if (!token) return;

      const response = await fetch(
        `${Res.api.gentian.BFF_BASE_URL}/results/${coordinateCommentsId}/coordinate-notes`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (!response.ok) {
        datadogLogs.logger.error('Failed to delete coordinate comment', {
          name: 'deleteCoordinateComment',
          response: {
            status: response.status,
            statusText: response.statusText,
          },
        });
        throw new Error('Failed to delete coordinate comment');
      }

      return;
    },
  );

  return {
    addResult,
    createCheckoutSession,
    createInstantHabmap,
    createProduct,
    createProject,
    createResult,
    deleteProject,
    deleteCoordinateComment,
    useAccount,
    useAllProducts,
    useAllProjects,
    useBNGUnitsByProjectId,
    useCoordinateCommentsByResultId,
    usePaymentStatus,
    useProducts,
    useProjectById,
    useProjects,
    useResultByProjectId,
    getResultByProjectIdFunction,
    useSharedResults,
    useUsage,
    publishResult,
    setResultsPermissions,
    updateAccount,
    updateCoordinateComments,
    updateProduct,
    updateProjectCode,
    updateProjectName,
    updateResult,
    uploadFindOutMorePDF,
  };
};

export default useGentian;
