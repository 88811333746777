import React, { createContext, useState, useEffect } from 'react';

interface ProgressContextType {
  sseUrl: string | null;
  setSseUrl: (value: string | null) => void;
  projectName: string;
  setProjectName: (value: string) => void;
  isProgressPopupOpen: boolean;
  setIsProgressPopupOpen: (value: boolean) => void;
}

export const ProgressContext = createContext<ProgressContextType>({
  sseUrl: null,
  setSseUrl: (value) => {
    return value;
  },
  projectName: '',
  setProjectName: (value) => value,
  isProgressPopupOpen: false,
  setIsProgressPopupOpen: (value) => value,
});

export const ProgressProvider = ({ children }) => {
  const [sseUrl, setSseUrl] = useState<string | null>(() => {
    return localStorage.getItem('sseUrl');
  });
  const [isProgressPopupOpen, setIsProgressPopupOpen] = useState(false);
  const [projectName, setProjectName] = useState('');

  useEffect(() => {
    if (sseUrl) {
      localStorage.setItem('sseUrl', sseUrl);
    } else {
      localStorage.removeItem('sseUrl');
    }
  }, [sseUrl]);

  return (
    <ProgressContext.Provider
      value={{ sseUrl, setSseUrl, projectName, setProjectName, isProgressPopupOpen, setIsProgressPopupOpen }}
    >
      {children}
    </ProgressContext.Provider>
  );
};
