import { OpenInNew } from '@mui/icons-material';
import { Link, Typography } from '@mui/material';

export interface ExternalLinkProps {
  href: string;
  children: React.ReactNode;
}

export const ExternalLink: React.FC<ExternalLinkProps> = ({ href, children }) => (
  <Link
    target="_blank"
    color="primary"
    href={href}
    sx={{
      display: 'flex',
      alignSelf: 'flex-end',
      alignItems: 'center',
      width: 'fit-content',
      gap: '2px',
    }}
  >
    <Typography>{children}</Typography>
    <OpenInNew fontSize="inherit" />
  </Link>
);
