import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { distinctivenessColorMap } from './distinctiveness-color.map';
import { habitatDescriptionsMap } from './habitat-descriptions.map';

export const ClassifyFeatureModal: React.FC<{
  featureClassification: { description: string; distinctiveness: string; condition: string };
  onFeaturesClassify?: (featureClassification, modifyAll) => void;
  onClose?: () => void;
  open: boolean;
}> = ({ featureClassification, onFeaturesClassify, open, onClose }) => {
  const [classification, setClassification] = React.useState(featureClassification);
  const [modifyAll, setModifyAll] = React.useState(false);

  const handleOnSave = () => {
    onFeaturesClassify(classification, modifyAll);
    handleClose();
  };

  const handleClose = () => {
    onClose?.();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          minWidth: '300px',
          width: '30vw',
          maxWidth: '800px',
          minHeight: '300px',
          maxHeight: '600px',
          backgroundColor: 'white',
          borderRadius: '4px',
          padding: '16px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          overflow: 'auto',
        }}
      >
        <Typography variant="h6">Classify feature</Typography>
        <Divider sx={{ mb: 2 }} />
        <Box sx={{ mx: 2 }}>
          <Typography variant="body1">Habitat Description</Typography>
          <Autocomplete
            isOptionEqualToValue={(option, value) => option[0] === value[0]}
            options={Object.entries(habitatDescriptionsMap)}
            getOptionLabel={(option) => option[1]}
            renderInput={(params) => <TextField {...params} label="Habitat Description" variant="standard" />}
            onChange={(e, v) => setClassification({ ...classification, description: v[0] })}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'start',
              alignItems: 'center',
              mb: 2,
              mt: 2,
            }}
          >
            <Typography variant="body1">Distinctiveness: </Typography>
            <Typography
              variant="body2"
              sx={{
                ml: 2,
                px: 2,
                borderRadius: 2,
                backgroundColor: distinctivenessColorMap[featureClassification.distinctiveness],
                width: 'fit-content',
              }}
            >
              {featureClassification.distinctiveness}
            </Typography>
          </Box>
          <Box
            sx={{
              mb: 2,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'start',
              alignItems: 'center',
            }}
          >
            <Typography variant="body1">Condition:</Typography>
            <Select
              sx={{ ml: 2 }}
              variant="standard"
              value={classification.condition}
              onChange={(e) => setClassification({ ...classification, condition: e.target.value })}
            >
              <MenuItem value="3">
                <Typography
                  variant="body2"
                  sx={{ ml: 2, px: 2, borderRadius: 2, backgroundColor: '#b5f4b7', width: 'fit-content' }}
                >
                  Good
                </Typography>
              </MenuItem>
              <MenuItem value="2.5">
                <Typography
                  variant="body2"
                  sx={{ ml: 2, px: 2, borderRadius: 2, backgroundColor: '#e9f4b5', width: 'fit-content' }}
                >
                  Fairly Good
                </Typography>
              </MenuItem>
              <MenuItem value="2">
                <Typography
                  variant="body2"
                  sx={{ ml: 2, px: 2, borderRadius: 2, backgroundColor: '#ffff8a', width: 'fit-content' }}
                >
                  Moderate
                </Typography>
              </MenuItem>
              <MenuItem value="1.5">
                <Typography
                  variant="body2"
                  sx={{ ml: 2, px: 2, borderRadius: 2, backgroundColor: '#ffd98a', width: 'fit-content' }}
                >
                  Fairly Poor
                </Typography>
              </MenuItem>
              <MenuItem value="1">
                <Typography
                  variant="body2"
                  sx={{ ml: 2, px: 2, borderRadius: 2, backgroundColor: '#ffb38a', width: 'fit-content' }}
                >
                  Poor
                </Typography>
              </MenuItem>
            </Select>
          </Box>
          <Box
            sx={{
              my: 2,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Checkbox
              checked={modifyAll}
              onChange={(e) => {
                setModifyAll(e.target.checked);
              }}
            />
            <Typography variant="body1">Change for all "Modified Grassland" polygons</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Divider sx={{ mb: 2 }} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <Button
              variant="contained"
              sx={{ backgroundColor: 'var(--gentian-tertiary)' }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={handleOnSave}>
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
